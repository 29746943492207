import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit } from '@angular/core';
import { AutorunComponent } from '@myshared/autorun.component';
import { Model } from '../../app.model';
import { InstanceOfferData } from '../buy.service';
import { I18NextService } from "angular-i18next";
import { trimInput } from '@myshared/utils';

@Component({
  selector: 'app-select-instance-name',
  templateUrl: './select-instance-name.component.html',
  styleUrls: []
})
export class SelectInstanceNameComponent extends AutorunComponent implements OnInit {

  @Input() public stepsBack = 1;
  @Input() public historyBack = true;
  @Input() public selectedDomain: string;
  @Input() public applianceName?: string;
  @Input() public isTrial = false;

  @Output() public readonly selected = new EventEmitter<InstanceOfferData>();
  @Output() public readonly backEmitter = new EventEmitter<void>();

  buttonDisabled: boolean = false;

  public instanceName = '';
  public validationError = '';

  constructor(private m: Model,
              private i18next: I18NextService) {
    super();
  }

  ngOnInit() {
    if (this.applianceName) {
      this.instanceName = this.applianceName;
      this.checkValidation(this.instanceName);
    }

    if (this.selectedDomain) {
      if (!this.selectedDomain.includes('https://')) {
        this.selectedDomain = `https://${this.selectedDomain}/`;
      }
    } else {
      this.selectedDomain = this.i18next.t('instancename_name_label') as string;
    }
  }

  onContinue() {
    this.disableContinueButton()
    // FIXME: A click on here should immediately trigger validation
    if (this.instanceName && !this.validationError) {
      const name = this.instanceName;
      this.selected.emit({
        name: trimInput(name),
      });
    }
  }

  disableContinueButton() {
    this.buttonDisabled = true;
    setTimeout(() => {
      this.buttonDisabled = false;
    }, 3000);

  }


  public back() {
    this.backEmitter.emit();
  }

  public onInstanceNameChanged(input: string) {
    this.checkValidation(input);
  }

  private checkValidation(instanceName: string) {
    this.validationError = '';
    if (instanceName === '') {
      this.validationError = 'instancename_name_error_required';
      return;
    }

    if (!/^[a-z]{4,}[a-z0-9-]*$/.test(instanceName)) {
      this.validationError = 'instancename_name_error_pattern';
      return;
    }

    if (instanceName.length > 20) {
      this.validationError = 'instancename_name_error_maxLength';
      return;
    }
  }
}


