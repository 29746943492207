import {WorkflowCspOneUpgradeCuComponent} from "./workflow-csp-one-upgrade-cu/workflow-csp-one-upgrade-cu.component";
import {WorkflowCspOneUpgradeSlaComponent} from "./workflow-csp-one-upgrade-sla/workflow-csp-one-upgrade-sla.component";
import {
  WorkflowCspOneDowngradeCuComponent
} from "./workflow-csp-one-downgrade-cu/workflow-csp-one-downgrade-cu.component";
import {
  WorkflowCspOneDowngradeSlaComponent
} from "./workflow-csp-one-downgrade-sla/workflow-csp-one-downgrade-sla.component";
import {WorkflowCspService} from "./workflow-csp.service";
import {
  WorkflowCspOneTrialCreateComponent
} from "./workflow-csp-one-trial-create/workflow-csp-one-trial-create.component";
import { WorkflowCspAddTrialPhonenumberComponent } from "./workflow-csp-add-trial-phonenumber/workflow-csp-add-trial-phonenumber.component";

export const workflowCspComponents= [
  WorkflowCspOneUpgradeCuComponent,
  WorkflowCspOneUpgradeSlaComponent,
  WorkflowCspOneDowngradeCuComponent,
  WorkflowCspOneDowngradeSlaComponent,
  WorkflowCspOneTrialCreateComponent,
  WorkflowCspAddTrialPhonenumberComponent
]

export const workflowCspServices = [
  WorkflowCspService,
]
