import {Directive, OnDestroy, OnInit} from '@angular/core';
import {WorkflowComponent} from '../workflow.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Model} from '../../app.model';
import {observable, runInAction} from 'mobx';
import {SaleOrder} from '../../buy/buy.model';
import {Subscription} from '../../subscription/subscription.model';
import {SubscriptionService} from '../../subscription/subscription.service';
import {firstValueFrom} from 'rxjs';

interface WorkflowModel {
  saleOrder?: SaleOrder;
  isTrialNumberSelected?: boolean;
}

interface TrialPhonenumber {
  phonenumber?: string;
}

@Directive()
export abstract class WorkflowAddTrialPhonenumberBaseComponent extends WorkflowComponent implements OnInit, OnDestroy {
  @observable
  public model: WorkflowModel;

  protected subscription: Subscription;
  protected subscriptions: Subscription[];
  protected trialPhoneNumber: TrialPhonenumber;

  constructor(
    protected m: Model,
    protected router: Router,
    protected route: ActivatedRoute,
    protected subscriptionService: SubscriptionService,
  ) {
    super(route);
  }

  async ngOnInit() {
    super.ngOnInit();

    this.autorun( async () => {
    });

    this.initWorkflow();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  protected abstract createOrder(): Promise<void>;

  protected abstract navigateToStep(url: any[], replace: boolean): void;

  protected initWorkflow() {

    const model: WorkflowModel = {};
    const trialPhoneNumber: TrialPhonenumber = {};

    runInAction(() => {
      this.model = model;
      this.trialPhoneNumber = trialPhoneNumber;
    });

    this.initialized = true;
  }

  public async onTrialPhonenumberSelected(/*isSelected: boolean*/) {
    await this.createOrder();
  }

  public onTrialPhonenumberBack() {
    window.history.back(); // needs fix
  }

  public onPhonenumberSelected(_: string) {
    this.router.navigate(['/subscriptions']);
  }

}
