import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, NG_VALIDATORS, ReactiveFormsModule} from '@angular/forms';
import {authConfigFactory, AuthService} from './auth/auth.service';
import {SubscriptionService} from './subscription/subscription.service';
import {WorkflowManagerComponent} from './workflow/workflow-manager/workflow-manager.component';
import {AppRoutingModule} from './app-routing.module';
import {Model} from './app.model';
import {SubscriptionListComponent} from './subscription/subscription-list/subscription-list.component';
import {SubscriptionCardComponent} from './subscription/subscription-card/subscription-card.component';
import { configure } from 'mobx';
import { SubscriptionViewComponent } from './subscription/subscription-view/subscription-view.component';
import {TopNavigationComponent} from './shared/top-navigation/top-navigation.component';
import {SideNavigationComponent} from './shared/side-navigation/side-navigation.component';
import { FooterComponent } from './shared/footer/footer.component';
import { CustomerListComponent } from './customer/customer-list/customer-list.component';
import {CustomerService} from './customer/customer.service';
import { CustomerCardComponent } from './customer/customer-card/customer-card.component';
import {PCFormsService} from '@myshared/pcform.service';
import {I18NextModule} from 'angular-i18next';
import { DynamicFormsCoreModule, DYNAMIC_VALIDATORS, Validator, ValidatorFactory, DYNAMIC_ERROR_MESSAGES_MATCHER } from '@ng-dynamic-forms/core';
import {matchOther} from '@myshared/matchOtherValidator';
import {DynamicFormsNGBootstrapUIModule} from '@ng-dynamic-forms/ui-ng-bootstrap';
import {CountryService} from './country/country.service';
import { AddCustomerComponent } from './customer/add-customer/add-customer.component';
import { SearchboxComponent } from '@myshared/searchbox/searchbox.component';
import { CompanyDetailsComponent } from './company/company-details/company-details.component';
import {CompanyService} from './company/company.service';
import { CompanyNavigationComponent } from './company/company-navigation/company-navigation.component';
import { CompanyContactsComponent } from './company/company-contacts/company-contacts.component';
import {ConfirmationService, MessageService} from 'primeng/api';
import { ContactCardComponent } from './company/contact-card/contact-card.component';
import { ContactDetailsComponent } from './company/contact-details/contact-details.component';
import { ContactInviteComponent } from './company/contact-invite/contact-invite.component';
import {ToastModule} from 'primeng/toast';
import {IsAdminGuard} from './is-admin.guard';
import {IsPartnerGuard} from './is-partner.guard';
import { CloudThankyouComponent } from './buy/cloud-thankyou/cloud-thankyou.component';
import { InvoiceAddressComponent } from './buy/invoice-address/invoice-address.component';
import { PaymentMethodComponent } from './buy/payment-method/payment-method.component';
import { SelectInstanceNameComponent } from './buy/select-instance-name/select-instance-name.component';
import {LoadingInterceptor} from './loading.interceptor';
import { ContentLoadingComponent } from '@myshared/content-loading/content-loading.component';
import { PriceCardComponent } from './buy/select-product/price-card/price-card.component';
import {KeepHtmlPipe} from '@myshared/keep-html.pipe';
import {LocalizedCurrencyNoSymbolPipe, LocalizedCurrencyPipe} from '@myshared/localized-currency.pipe';
import {BuyService} from './buy/buy.service';
import { SelectCustomerComponent } from './buy/select-customer/select-customer.component';
import { BuyThankyouComponent } from './buy/buy-thankyou/buy-thankyou.component';
import { SubscriptionSupportTypeCardComponent } from './subscription/subscription-support-type-card/subscription-support-type-card.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { RequestInterceptor} from '@myshared/http_interceptor';
import { ChangePasswordComponent } from './settings/change-password/change-password.component';
import { InviteComponent } from './invite/invite.component';
import { NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {IsAlreadyAuthenticatedGuard} from './is-already-authenticated.guard';
import { SubscriptionCardsComponent } from './subscription/subscription-cards/subscription-cards.component';
import { SubscriptionTitleComponent } from './subscription/subscription-title/subscription-title.component';
import {LocalizedDatePipe} from '@myshared/localized-date.pipe';
import {LocalizedDateTimePipe} from '@myshared/localized-datetime.pipe';
import { ContentHeaderComponent } from '@myshared/content-header/content-header.component';
import { ProfileListComponent } from './partner/profile/profile-list/profile-list.component';
import { ProfileViewComponent } from './partner/profile/profile-view/profile-view.component';
import {ProfileService} from './partner/profile.service';
import { ProfileDetailsComponent } from './partner/profile/profile-details/profile-details.component';
import { ProfileCardComponent } from './partner/profile/profile-card/profile-card.component';
import {LanguageService} from './language.service';
import { ChooseLanguageComponent } from '@myshared/choose-language/choose-language.component';
import {NativeElementInjectorDirective} from '@myshared/nativeElementInjector.directive';
import { BecomePartnerComponent } from './partner/affiliate/become-partner/become-partner.component';
import { PartnerComponent } from './partner/partner.component';
import { PartnerViewComponent } from './partner/affiliate/partner-view/partner-view.component';
import {AffiliateService} from './partner/affiliate/affiliate.service';
import { PartnershipViewComponent } from './partner/affiliate/partnership/partnership-view/partnership-view.component';
import { PartnershipNavigationComponent } from './partner/affiliate/partnership/partnership-navigation/partnership-navigation.component';
import { PartnershipAchievementContainerComponent } from './partner/affiliate/partnership/partnership-achievement-container/partnership-achievement-container.component';
import { PartnershipAchievementProgressCardComponent } from './partner/affiliate/partnership/partnership-achievement-progress-card/partnership-achievement-progress-card.component';
import { PartnershipAchievementCompleteCardComponent } from './partner/affiliate/partnership/partnership-achievement-complete-card/partnership-achievement-complete-card.component';
import { PartnerPendingComponent } from './partner/affiliate/partner-pending/partner-pending.component';
import {TooltipModule} from 'primeng/tooltip';
import { TestListComponent } from './partner/test-list/test-list.component';
import {PartnerTestService} from './partner/partnertest.service';
import { TestCardComponent } from './partner/test-card/test-card.component';
import { TestExecuteComponent } from './partner/test-execute/test-execute.component';
import { AgreementManagementComponent } from './agreement-management/agreement-management.component';
import {AgreementService} from './agreement-management/agreement.service';
import { AgreementCardComponent } from './agreement-management/agreement-card/agreement-card.component';
import { AgreementListComponent } from './agreement-management/agreement-list/agreement-list.component';
import { AgreementAckComponent } from './agreement-management/agreement-ack/agreement-ack.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {PascomCheckboxComponent} from '@myshared/pascom-checkbox/pascom-checkbox.component';
import { AgreementFreeIdleWarningComponent } from './agreement-management/agreement-free-idle-warning/agreement-free-idle-warning.component';
import { SelectCsphostNameComponent } from './buy/select-csphost-name/select-csphost-name.component';
import { SubscriptionTypeBadgeComponent } from './subscription/subscription-type-badge/subscription-type-badge.component';
import {CompanyPaymentDetailsComponent} from './company/company-payment-details/company-payment-details.component';
import { VerifyAccountFailedComponent } from './verify-account-failed/verify-account-failed.component';
import { WorkflowCancelSubscriptionComponent } from './workflow/workflow-cancel-subscription/workflow-cancel-subscription.component';
import { SubscriptionCancelConfirmComponent } from './subscription/subscription-cancel-confirm/subscription-cancel-confirm.component';
import {SlaCardComponent} from './buy/select-sla/sla-card/sla-card.component';
import {BuyThankyouContentComponent} from './buy/buy-thankyou-content/buy-thankyou-content.component';
import { TicketListComponent } from './support/ticket-list/ticket-list.component';
import { TicketCardComponent } from './support/ticket-card/ticket-card.component';
import { TicketDetailsComponent } from './support/ticket-details/ticket-details.component';
import { ArticleCardComponent } from './support/article-card/article-card.component';
import {EditorModule} from 'primeng/editor';
import { CreateTicketComponent } from './support/create-ticket/create-ticket.component';
import { SupportSelectSubscriptionComponent } from './support/create-ticket/support-select-subscription/support-select-subscription.component';
import { ArticleEditorComponent } from './support/article-editor/article-editor.component';
import { CreateTicketFormComponent } from './support/create-ticket/create-ticket-form/create-ticket-form.component';
import { ArticleAttachmentsComponent } from './support/article-attachments/article-attachments.component';
import {FileUploadModule} from 'primeng/fileupload';
import { UploadAttachmentsFormComponent } from './support/upload-attachments-form/upload-attachments-form.component';
import { CloseTicketWarningComponent } from './support/ticket-details/close-ticket-warning/close-ticket-warning.component';
import { errorMessageOnChangeMatcher } from '@myshared/provider';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {OrderListComponent} from './order/order-list/order-list.component';
import {OrderCardComponent} from './order/order-card/order-card.component';
import {OrderService} from './order/order.service';
import {SelectSubscriptionProductComponent} from './subscription/select-subscription-product/select-subscription-product.component';
import {ProductCardComponent} from './subscription/select-subscription-product/product-card/product-card.component';
import {SelectContactComponent} from './buy/select-contact/select-contact.component';
import {PhonenumberComponent} from './buy/phonenumber/phonenumber.component';
import {CompanyAddressComponent} from './buy/company-address/company-address.component';
import {SubscriptionOneDetailsCardComponent} from './subscription/subscription-one-details-card/subscription-one-details-card.component';
import {OrderTrialPhonenumberComponent} from './buy/order-trial-phonenumber/order-trial-phonenumber.component';
import {MaintenanceComponent} from '@myshared/maintenance/maintenance/maintenance.component';
import {MaintenanceService} from '@myshared/maintenance/maintenance.service';
import {MaintenanceApiComponent} from '@myshared/maintenance/maintenance/maintenance-api/maintenance-api.component';
import {MaintenanceScheduledComponent} from '@myshared/maintenance/maintenance/maintenance-scheduled/maintenance-scheduled.component';
import {MultiSelectModule} from 'primeng/multiselect';
import {PascomDropdownComponent} from '@myshared/pascom-dropdown-multiselect/pascom-dropdown.component';
import {PhonenumberListComponent} from './phonenumber/phonenumber-list/phonenumber-list.component';
import {PhonenumberCardComponent} from './phonenumber/phonenumber-card/phonenumber-card.component';
import {PhonenumberService} from './phonenumber/phonenumber.service';
import {PhonenumberSingleComponent} from './phonenumber/phonenumber-card/phonenumber-single/phonenumber-single.component';
import {PhonenumberBlockComponent} from './phonenumber/phonenumber-card/phonenumber-block/phonenumber-block.component';
import {CustomerNavigationComponent} from './customer/customer-navigation/customer-navigation.component';
import {CustomerSubscriptionsComponent} from './customer/customer-subscriptions/customer-subscriptions.component';
import {CustomerContactsComponent} from './customer/customer-contacts/customer-contacts.component';
import {CustomerDetailsComponent} from './customer/customer-details/customer-details.component';
import {WorkflowUpgradeOneComponent} from './workflow/workflow-upgrade-one/workflow-upgrade-one.component';
import {UpgradeOneComponent} from './buy/upgrade-one/upgrade-one.component';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputTextModule} from 'primeng/inputtext';
import {SelectOneSlaComponent} from './buy/select-one-sla/select-one-sla.component';
import {WorkflowPhonenumbersComponent} from './workflow/workflow-phonenumbers/workflow-phonenumbers.component';
import {SelectOneSubscriptionComponent} from './buy/select-one-subscription/select-one-subscription.component';
import {SelectPhonenumberTypeComponent} from './buy/select-phonenumber-type/select-phonenumber-type.component';
import {PhonenumberTypeCardComponent} from './buy/select-phonenumber-type/phonenumber-type-card/phonenumber-type-card.component';
import { PhonenumbersTypeSummaryComponent } from './buy/select-phonenumber-type/select-phonenumbers-type-summary/phonenumbers-type-summary.component';
import {SelectPhonenumberBlocksizeComponent} from './buy/select-phonenumber-blocksize/select-phonenumber-blocksize.component';
import {SelectPhonenumberComponent} from './buy/select-phonenumber/select-phonenumber.component';
import { SelectLocIndPhonenumberComponent } from './buy/select-phonenumber-loc-ind/select-loc-ind-phonenumber.component';
import { SelectLocIndPhonenumberCardComponent } from './buy/select-phonenumber-loc-ind/select-loc-ind-phonenumber-card/select-loc-ind-phonenumber-card.component';
import {SelectPhonenumberCardComponent} from './buy/select-phonenumber/select-phonenumber-card/select-phonenumber-card.component';
import {CheckoutPhonenumberSummaryComponent} from './buy/checkout-phonenumber-summary/checkout-phonenumber-summary.component';
import {CheckoutPhonenumberSummaryProductsComponent} from './buy/checkout-phonenumber-summary/checkout-phonenumbersummary-products/checkout-phonenumber-summary-products.component';
import {CheckoutPhonenumberSummaryPriceDetailsComponent} from './buy/checkout-phonenumber-summary/checkout-phonenumber-summary-price-details/checkout-phonenumber-summary-price-details.component';
import {FileUploaderComponent} from '@myshared/file-uploader/file-uploader.component';
import {UploadCompanyLicenseComponent} from './buy/upload-company-license/upload-company-license.component';
import {BuyPhonenumberThankyouComponent} from './buy/buy-phonenumber-thankyou/buy-phonenumber-thankyou.component';
import {FormatPhonenumberPipe} from '@myshared/format-phonenumber.pipe';
import {CheckoutOneSummaryComponent} from './buy/checkout-one-summary/checkout-one-summary.component';
import {SubscriptionOneCancelPeriodComponent} from './subscription/subscription-one-cancel-period/subscription-one-cancel-period.component';
import { SubscriptionItemizedBillingComponent } from './subscription/subscription-itemized-billing/subscription-itemized-billing.component';
import { WorkflowItemizedBillingComponent } from './workflow/workflow-itemized-billing/workflow-itemized-billing.component';
import { ItemizedBillingCardComponent } from './itemized-billing/itemized-billing-card/itemized-billing-card.component';
import { ChangeItemizedBillingComponent } from './itemized-billing/change-itemized-billing/change-itemized-billing.component';
import {PhonenumberPortComponent} from './phonenumber/phonenumber-port/phonenumber-port.component';
import { PhonenumberPortPrepareComponent } from './phonenumber/phonenumber-port-prepare/phonenumber-port-prepare.component';
import { IconCardComponent } from '@myshared/icon-card/icon-card.component';
import { DownloadPortFormComponent } from './phonenumber/download-port-form/download-port-form.component';
import { UploadPortFormComponent } from './phonenumber/upload-port-form/upload-port-form.component';
import { UploadPhonebillFormComponent } from './phonenumber/upload-phonebill-form/upload-phonebill-form.component';
import {compareNumber} from '@myshared/compareNumberValidator';
import {FormatByteSizePipe} from '@myshared/format-download-size.pipe';
import {
  PhonenumberPortSelectSizeComponent
} from './phonenumber/phonenumber-port/phonenumber-port-select-size/phonenumber-port-select-size.component';
import {
  PhonenumberPortProviderComponent
} from './phonenumber/phonenumber-port/phonenumber-port-provider/phonenumber-port-provider.component';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {
  PhonenumberPortBlockComponent
} from './phonenumber/phonenumber-port/phonenumber-port-block/phonenumber-port-block.component';
import {
  PhonenumberPortSingleNumberComponent
} from './phonenumber/phonenumber-port/phonenumber-port-single-number/phonenumber-port-single-number.component';
import {
  PhonenumberPortSelectDateComponent
} from './phonenumber/phonenumber-port-date/phonenumber-port-date.component';
import {AuthModule,StsConfigLoader} from 'angular-auth-oidc-client';
import {HttpClient} from '@angular/common/http';
import {AuthSmsComponent} from './auth/auth-sms/auth-sms.component';
import {AuthGuard} from './auth/auth.guard';
import {AgreementGuard} from './agreement-management/agreement.guard';
import {CompanyChooseNewExistingComponent} from './company/company-choose-new-existing/company-choose-new-existing.component';
import {CompanyGuard} from './company/company.guard';
import {CompanyAddExistingComponent} from './company/company-choose-new-existing/company-add-existing/company-add-existing.component';
import {CompanyCreateComponent} from './company/company-choose-new-existing/company-create/company-create.component';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {AuthFailedComponent} from './auth/auth-failed/auth-failed.component';
import {emailAddressValidator} from '@myshared/emailValidator';
import {
  PhonenumberBillCompanyNameComponent
} from './phonenumber/phonenumber-bill-company-name/phonenumber-bill-company-name.component';
import { CloudStatusComponent } from './support/cloud-status/cloud-status.component';
import { TariffCardComponent } from './tariff/flatrates/tariff-card/tariff-card.component';
import { TariffPlaceOrderComponent } from './tariff/flatrates/trunk-place-order/tariff-place-order.component';
import { WorkflowTariffComponent } from './workflow/workflow-tariff/workflow-tariff.component';
import { TariffSelectComponent } from './tariff/flatrates/tariff-select/tariff-select.component';
import { TariffInfoCardComponent } from './tariff/flatrates/tariff-info-card/tariff-info-card.component';
import { WorkflowTariffCancelComponent } from './workflow/workflow-tariff-cancel/workflow-tariff-cancel.component';
import {TariffService} from './tariff/tariff.service';
import {TariffThankyouComponent} from './tariff/tariff-thankyou/tariff-thankyou.component';
import {WarningConfirmBoxComponent} from './shared/warning-confirm-box/warning-confirm-box.component';
import {DropdownModule} from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import {AlertMessageComponent} from '@myshared/alert-messages/alert-message.component';
import { NgxMaskModule } from 'ngx-mask'
import {BuyThankyouPartnerComponent} from "./buy/buy-thankyou-partner/buy-thankyou-partner.component";
import { OfferCompleteComponent } from './buy/offer-complete/offer-complete.component';
import { SelectInvoiceContactComponent } from './buy/select-invoice-contact/select-invoice-contact.component';
import {SelectDomainComponent} from "./buy/select-domain/select-domain.component";
import {
  CancelProviderConnectionComponent
} from "./phonenumber/cancel-provider-connection/cancel-provider-connection.component";
import {AlertCardComponent} from "@myshared/alert-card/alert-card.component";
import {NfrOnePrepareComponent} from "./buy/nfr-one-prepare/nfr-one-prepare.component";
import {TopStickyMessageBarComponent} from "./shared/top-sticky-message-bar/top-sticky-message-bar.component";
import { PhonenumberUnavailableComponent } from './phonenumber/phonenumber-unavailable/phonenumber-unavailable.component';
import {DynamicFormsPrimeNGUIModule} from "@ng-dynamic-forms/ui-primeng";
import { FilterComponent } from '@myshared/filter/filter.component';
import {NoContentMessageComponent} from "@myshared/no-content-message/no-content-message.component";
import {InputTextareaModule} from "primeng/inputtextarea";
import {
  SubscriptionCancelCustomerConfirmComponent
} from "./subscription/subscription-cancel-customer-confirm/subscription-cancel-customer-confirm.component";

import {workflowComponents, workflowServices} from "./workflow/workflow.module";
import {
  SubscriptionConcurrentUserComponent
} from "./subscription/subscription-concurrent-user/subscription-concurrent-user.component";
import {SubscriptionSlaComponent} from "./subscription/subscription-sla/subscription-sla.component";
import {
  SubscriptionTrialDetailsComponent
} from "./subscription/subscription-trial-details/subscription-trial-details.component";
import {
  SubscriptionOneDowngradeCuComponent
} from "./subscription/subscription-one-downgrade-cu/subscription-one-downgrade-cu.component";
import {
  SubscriptionOneDowngradeSlaComponent
} from "./subscription/subscription-one-downgrade-sla/subscription-one-downgrade-sla.component";
import {ToastService} from "./toast.service";
import {SubscriptionCreditsComponent} from "./subscription/subscription-credits/subscription-credits.component";
import {
  SubscriptionOneCancelButtonComponent
} from "./subscription/subscription-one-cancel-button/subscription-one-cancel-button.component";
import {HeadlinePageComponent} from "./shared/headline-page/headline-page.component";
import {HeadlineContentComponent} from "./shared/headline-content/headline-content.component";
import {CardComponent} from "./shared/card/card.component";
import {FaIconComponent} from "./shared/fa-icon/fa-icon.component";
import {SelectTenantComponent} from "./shared/select-tenant/select-tenant.component";
import {TenantMigrationComponent} from "./one-click-migration/tenant-migration/tenant-migration.component";
import {NavigationService} from "./navigation.service";
import {
  PhonenumberPortAtComponent
} from "./phonenumber/phonenumber-port/phonenumber-port-at/phonenumber-port-at.component";
import {
  PhonenumberPortDeComponent
} from "./phonenumber/phonenumber-port/phonenumber-port-de/phonenumber-port-de.component";
import {
  PhonenumberPortOneNumberComponent
} from "./phonenumber/phonenumber-port/phonenumber-port-one-number/phonenumber-port-one-number.component";
import {
  PhonenumberAtContactSalesComponent
} from "./phonenumber/phonenumber-at-contact-sales/phonenumber-at-contact-sales.component";
import {UserMenuComponent} from "./shared/user-menu/user-menu.component";
import {FallbackNumberCardComponent} from "./subscription/fallback-number-card/fallback-number-card.component";
import {phoneNumberValidator} from "@myshared/phoneNumberValidator";
import {AnalyticsService} from "./analytics.service";
import {PageInfoMessageBox} from "./shared/page-info-message-box/page-info-message-box";

@NgModule({
  declarations: [
    AppComponent,
    SubscriptionListComponent,
    WorkflowManagerComponent,
    SubscriptionCardComponent,
    SubscriptionViewComponent,
    TopNavigationComponent,
    SideNavigationComponent,
    FooterComponent,
    CustomerListComponent,
    CustomerCardComponent,
    CustomerDetailsComponent,
    CustomerNavigationComponent,
    CustomerSubscriptionsComponent,
    CustomerContactsComponent,
    AddCustomerComponent,
    SearchboxComponent,
    CompanyDetailsComponent,
    CompanyNavigationComponent,
    CompanyContactsComponent,
    ContactCardComponent,
    ContactDetailsComponent,
    ContactInviteComponent,
    CloudThankyouComponent,
    InvoiceAddressComponent,
    PaymentMethodComponent,
    SelectInstanceNameComponent,
    ContentLoadingComponent,
    PriceCardComponent,
    LocalizedCurrencyPipe,
    LocalizedCurrencyNoSymbolPipe,
    KeepHtmlPipe,
    LocalizedDatePipe,
    LocalizedDateTimePipe,
    SelectCustomerComponent,
    BuyThankyouComponent,
    SubscriptionSupportTypeCardComponent,
    ThankYouComponent,
    ChangePasswordComponent,
    InviteComponent,
    SubscriptionCardsComponent,
    SubscriptionTitleComponent,
    ContentHeaderComponent,
    ProfileListComponent,
    ProfileViewComponent,
    ProfileDetailsComponent,
    ProfileCardComponent,
    ChooseLanguageComponent,
    NativeElementInjectorDirective,
    TestListComponent,
    TestCardComponent,
    TestExecuteComponent,
    AgreementManagementComponent,
    AgreementCardComponent,
    AgreementListComponent,
    AgreementAckComponent,
    BecomePartnerComponent,
    PartnerComponent,
    PartnerViewComponent,
    PartnershipViewComponent,
    PartnershipNavigationComponent,
    PartnershipAchievementContainerComponent,
    PartnershipAchievementProgressCardComponent,
    PartnershipAchievementCompleteCardComponent,
    PartnerPendingComponent,
    PascomCheckboxComponent,
    PascomDropdownComponent,
    AgreementFreeIdleWarningComponent,
    SelectCsphostNameComponent,
    SubscriptionTypeBadgeComponent,
    CompanyPaymentDetailsComponent,
    SubscriptionTypeBadgeComponent,
    VerifyAccountFailedComponent,
    WorkflowCancelSubscriptionComponent,
    SubscriptionCancelConfirmComponent,
    SlaCardComponent,
    BuyThankyouContentComponent,
    TicketListComponent,
    TicketCardComponent,
    TicketDetailsComponent,
    ArticleCardComponent,
    CreateTicketComponent,
    SupportSelectSubscriptionComponent,
    ArticleEditorComponent,
    CreateTicketFormComponent,
    ArticleAttachmentsComponent,
    UploadAttachmentsFormComponent,
    CloseTicketWarningComponent,
    OrderListComponent,
    OrderCardComponent,
    SelectSubscriptionProductComponent,
    ProductCardComponent,
    SelectContactComponent,
    PhonenumberComponent,
    CompanyAddressComponent,
    SubscriptionOneDetailsCardComponent,
    OrderTrialPhonenumberComponent,
    OrderCardComponent,
    MaintenanceComponent,
    MaintenanceApiComponent,
    MaintenanceScheduledComponent,
    PhonenumberListComponent,
    PhonenumberCardComponent,
    PhonenumberSingleComponent,
    PhonenumberBlockComponent,
    WorkflowUpgradeOneComponent,
    UpgradeOneComponent,
    SelectOneSlaComponent,
    WorkflowPhonenumbersComponent,
    SelectOneSubscriptionComponent,
    SelectPhonenumberTypeComponent,
    PhonenumberTypeCardComponent,
    PhonenumbersTypeSummaryComponent,
    SelectPhonenumberBlocksizeComponent,
    SelectPhonenumberComponent,
    SelectLocIndPhonenumberComponent,
    SelectLocIndPhonenumberCardComponent,
    SelectPhonenumberCardComponent,
    CheckoutPhonenumberSummaryComponent,
    CheckoutPhonenumberSummaryProductsComponent,
    CheckoutPhonenumberSummaryPriceDetailsComponent,
    FileUploaderComponent,
    UploadCompanyLicenseComponent,
    BuyPhonenumberThankyouComponent,
    FormatPhonenumberPipe,
    CheckoutOneSummaryComponent,
    SubscriptionOneCancelPeriodComponent,
    SubscriptionItemizedBillingComponent,
    WorkflowItemizedBillingComponent,
    ItemizedBillingCardComponent,
    ChangeItemizedBillingComponent,
    PhonenumberPortComponent,
    PhonenumberPortAtComponent,
    PhonenumberPortDeComponent,
    PhonenumberPortPrepareComponent,
    IconCardComponent,
    DownloadPortFormComponent,
    UploadPortFormComponent,
    UploadPhonebillFormComponent,
    FormatByteSizePipe,
    PhonenumberPortSelectSizeComponent,
    PhonenumberPortProviderComponent,
    PhonenumberPortBlockComponent,
    PhonenumberPortSingleNumberComponent,
    PhonenumberPortOneNumberComponent,
    PhonenumberPortSelectDateComponent,
    CloseTicketWarningComponent,
    AuthSmsComponent,
    CompanyChooseNewExistingComponent,
    CompanyAddExistingComponent,
    CompanyCreateComponent,
    AuthFailedComponent,
    PhonenumberBillCompanyNameComponent,
    CloudStatusComponent,
    TariffCardComponent,
    TariffPlaceOrderComponent,
    TariffSelectComponent,
    WorkflowTariffComponent,
    TariffInfoCardComponent,
    WorkflowTariffCancelComponent,
    TariffThankyouComponent,
    WarningConfirmBoxComponent,
    AlertMessageComponent,
    BuyThankyouPartnerComponent,
    OfferCompleteComponent,
    SelectInvoiceContactComponent,
    SelectDomainComponent,
    CancelProviderConnectionComponent,
    AlertCardComponent,
    NfrOnePrepareComponent,
    TopStickyMessageBarComponent,
    PhonenumberUnavailableComponent,
    FilterComponent,
    NoContentMessageComponent,
    SubscriptionCancelCustomerConfirmComponent,
    SubscriptionConcurrentUserComponent,
    SubscriptionSlaComponent,
    SubscriptionTrialDetailsComponent,
    SubscriptionOneDowngradeCuComponent,
    SubscriptionOneDowngradeSlaComponent,
    SubscriptionCreditsComponent,
    SubscriptionOneCancelButtonComponent,
    HeadlinePageComponent,
    HeadlineContentComponent,
    CardComponent,
    FaIconComponent,
    SelectTenantComponent,
    TenantMigrationComponent,
    PhonenumberAtContactSalesComponent,
    UserMenuComponent,
    FallbackNumberCardComponent,
    PageInfoMessageBox,
    ...workflowComponents,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    DynamicFormsCoreModule.forRoot(),
    DynamicFormsNGBootstrapUIModule,
    DynamicFormsPrimeNGUIModule,
    I18NextModule.forRoot(),
    PdfViewerModule,
    ToastModule,
    NgbDropdownModule,
    TooltipModule,
    EditorModule,
    FileUploadModule,
    MultiSelectModule,
    InputNumberModule,
    ConfirmDialogModule,
    AutoCompleteModule,
    CalendarModule,
    InputTextModule,
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: authConfigFactory,
        deps: [HttpClient],
      },
    }),
    DropdownModule,
    NgxMaskModule.forRoot(),
    InputTextareaModule,
  ],
  providers: [
    Model,
    AuthService,
    SubscriptionService,
    CountryService,
    AgreementService,
    CustomerService,
    PCFormsService,
    CompanyService,
    BuyService,
    MessageService,
    ProfileService,
    AffiliateService,
    LanguageService,
    PartnerTestService,
    OrderService,
    PhonenumberService,
    IsAdminGuard,
    IsPartnerGuard,
    IsAlreadyAuthenticatedGuard,
    MaintenanceService,
    AuthGuard,
    AgreementGuard,
    CompanyGuard,
    ConfirmationService,
    TariffService,
    ToastService,
    NavigationService,
    AnalyticsService,
    ...workflowServices,
    {
      provide: NG_VALIDATORS,
      useValue: matchOther,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useValue: compareNumber,
      multi: true
    },
    {
      provide: DYNAMIC_VALIDATORS,
      useValue: new Map<string, Validator | ValidatorFactory>([
        ['matchOther', matchOther],
        ['compareNumber', compareNumber],
        ['email', emailAddressValidator],
        ['phoneNumber', phoneNumberValidator],
      ])
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    {
      provide: DYNAMIC_ERROR_MESSAGES_MATCHER,
      useValue: errorMessageOnChangeMatcher
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

configure({
  enforceActions: 'always'
});
