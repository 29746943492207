import {Routes} from "@angular/router";
import {WorkflowCspAddTrialPhonenumberComponent} from "./workflow-csp-add-trial-phonenumber.component";


export const workflowCspAddTrialPhonenumberRouting: Routes = [
  {
    path: 'csp/trial-phonenumber', component: WorkflowCspAddTrialPhonenumberComponent, children: [
      {path: 'add-phonenumber', component: WorkflowCspAddTrialPhonenumberComponent, data: {step: 'add-phonenumber'}},
      {path: 'completed', component: WorkflowCspAddTrialPhonenumberComponent, data: {step: 'completed'}},
    ]
  },
]
