<div *ngIf="subscription">
  <app-subscription-one-cancel-period
    *ngIf="isOne && workflowStep === 'select-next-subscription-cancel-period'"
    [subscription]="subscription"
    (selectCancelPeriodEvent)="onCancelPeriodSelected($event)"
    (backEvent)="resetWorkflowAndRedirect()"
  ></app-subscription-one-cancel-period>

  <app-subscription-cancel-confirm
    *ngIf="workflowStep === 'confirm-subscription-cancel'"
    [subscription]="subscription"
    [isSubscriptionCancellingNow]="isSubscriptionCancellingNow"
    (confirmCancelEvent)="onCancelConfirmed()"
    (backEvent)="resetWorkflowAndRedirect()"
  ></app-subscription-cancel-confirm>

  <app-buy-thankyou-partner
          *ngIf="workflowStep === 'partner-cancel-subscription-done'"
          [orderName]="saleOrder.name">
  </app-buy-thankyou-partner>

</div>
