import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {I18NextService} from 'angular-i18next';
import {Subscription} from '../subscription.model';
import {LocalizedDatePipe} from '@myshared/localized-date.pipe';
import {Model} from "../../app.model";
import {Contact} from "../../company/company.model";
import {AutorunComponent} from "@myshared/autorun.component";
import {autorun} from "mobx";

@Component({
  selector: 'app-subscription-cancel-confirm',
  templateUrl: './subscription-cancel-confirm.component.html',
  providers: [LocalizedDatePipe]
})
export class SubscriptionCancelConfirmComponent extends AutorunComponent implements OnInit {

  @Input() subscription: Subscription;
  @Input() isSubscriptionCancellingNow: boolean;
  @Output() confirmCancelEvent = new EventEmitter<boolean>();
  @Output() backEvent = new EventEmitter<any>();

  private contact: Contact;

  constructor(private i18next: I18NextService,
              private m: Model,
              private datePipe: LocalizedDatePipe) {
    super();
  }

  ngOnInit() {
    autorun(() => {
      const customers = this.m.customer?.customers;
      if (!this.subscription.is_my && customers && !this.m.account.currentUser.companyIsPC20Csp) {
        this.contact = customers.find(r =>  r.id === this.subscription?.customer_id)?.contacts[0];
      }
    });
  }

  onCancellingSubscriptionConfirmed() {
    this.confirmCancelEvent.emit(this.isSubscriptionCancellingNow);
  }

  get nextInvoiceDate() {
    return this.datePipe.transform(this.subscription.next_invoice);
  }

  get pascomOneEndDate() {
    return this.datePipe.transform(this.subscription.nextPossibleEndDate);
  }

  back() {
    this.backEvent.emit();
  }

  public get cancelDate() {
    return this.subscription.isOne ? this.pascomOneEndDate : this.nextInvoiceDate
  }

  get warningPointsList() {
    if (this.subscription.isOne) {
      return [
        this.i18next.t('cancel_warning_deleted_after',
          { nextInvoice: this.pascomOneEndDate, interpolation: { escapeValue: false } }) as string,
        this.i18next.t('cancel_warning_loose_data_after',
          { nextInvoice: this.pascomOneEndDate, interpolation: { escapeValue: false } }) as string,
        this.i18next.t('cancel_warning_no_calls_after',
          { nextInvoice: this.pascomOneEndDate, interpolation: { escapeValue: false } }) as string,
        this.i18next.t('cancel_one_warning_phonenumbers',
          { date: this.pascomOneEndDate, interpolation: { escapeValue: false } }) as string,
        this.i18next.t('cancel_warning_noconnect_pascomclient_after',
          { nextInvoice: this.pascomOneEndDate, interpolation: { escapeValue: false } }),
        this.i18next.t('cancel_warning_subscription_cannot_open_again') as string
      ];
    }
  }

  public get isNotMy() {
    if (this.m.account.currentUser.companyIsPC20Csp) {
      return false;
    }
    return !this.subscription.is_my;
  }

  public get customerMail(): string {
    return this.contact.email;
  }
}
