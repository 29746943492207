import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Model} from '../../app.model';
import {WorkflowService} from '../workflow.service';
import {AutorunComponent} from '@myshared/autorun.component';

@Component({
  selector: 'app-workflow-manager',
  templateUrl: './workflow-manager.component.html',
  styleUrls: []
})
export class WorkflowManagerComponent extends AutorunComponent implements OnInit {

  public currentWorkflow: string = '';

  public isInWorkflow: boolean;
  public isCancelSubscription: boolean;
  public isOneUpgrade: boolean;
  public isPhonenumbers: boolean;
  public isItemizedBilling: boolean;
  public isTariff: boolean;
  public isTariffCancel: boolean;
  public isCreateNfrOne: boolean;
  public isOneTrialPartner: boolean;

  constructor(private router: Router, private route: ActivatedRoute, public m: Model, private _wSvc: WorkflowService) {
    super();
  }

  ngOnInit() {
    this.autorun(() => {
      this.currentWorkflow = this.m.workflow.name;
      this.isCancelSubscription = this.m.workflow.isCancelSubscription;
      this.isInWorkflow = this.m.workflow.inWorkflow;
      this.isOneUpgrade = this.m.workflow.isOneUpgrade;
      this.isPhonenumbers = this.m.workflow.isPhonenumbers;
      this.isItemizedBilling = this.m.workflow.isItemizedBilling;
      this.isTariff = this.m.workflow.isTariff;
      this.isTariffCancel = this.m.workflow.isTariffCancel;
      this.isCreateNfrOne = this.m.workflow.isNfrOne;
      this.isOneTrialPartner = this.m.workflow.isOneTrialPartner;
    });
    this.route.paramMap.subscribe((params: ParamMap) => this._wSvc.setWorkflowName(params.get('b64')));
  }
}
