import {Routes} from "@angular/router";
import {WorkflowPartnerAddTrialPhonenumberComponent} from "./workflow-partner-add-trial-phonenumber.component";


export const workflowPartnerAddTrialPhonenumberRouting: Routes = [
  {
    path: 'partner/trial-phonenumber', component: WorkflowPartnerAddTrialPhonenumberComponent, children: [
      {path: 'add-phonenumber', component: WorkflowPartnerAddTrialPhonenumberComponent, data: {step: 'add-phonenumber'}},
      {path: 'completed', component: WorkflowPartnerAddTrialPhonenumberComponent, data: {step: 'completed'}},
    ]
  },
]
