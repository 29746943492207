import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Model} from '../../app.model';
import {SubscriptionService} from '../../subscription/subscription.service';
import {AutorunComponent} from '@myshared/autorun.component';
import {Subscription} from '../../subscription/subscription.model';
import {BuyService} from "../../buy/buy.service";
import {firstValueFrom} from "rxjs";
import {CustomerService} from "../../customer/customer.service";
import {I18NextService} from "angular-i18next";
import {SaleOrder} from "../../buy/buy.model";

type WorkflowCancelSubscriptionStep =
  | 'select-next-subscription-cancel-period'
  | 'confirm-subscription-cancel'
  | 'partner-cancel-subscription-done';

@Component({
  selector: 'app-workflow-cancel-subscription',
  templateUrl: './workflow-cancel-subscription.component.html'
})
export class WorkflowCancelSubscriptionComponent extends AutorunComponent implements OnInit {

  public workflowStep: WorkflowCancelSubscriptionStep = 'select-next-subscription-cancel-period';
  public applianceId: string;
  public subscription: Subscription;
  public isSubscriptionCancellingNow = false;
  private backroute: string[] = null;

  public saleOrder: SaleOrder;

  constructor(private m: Model,
              private route: ActivatedRoute,
              private customerService: CustomerService,
              private router: Router,
              private buyService: BuyService,
              private i18next: I18NextService,
              private subscriptionService: SubscriptionService) {
    super();
  }

  ngOnInit() {
    this.autorun(() => {
      this.saleOrder = this.m.buy.currentOrder;
    });

    this.route.paramMap.subscribe((params: ParamMap) => {
      if (params.has('fromwf')) {
        this.backroute = ['/do', params.get('fromwf')];
      }

      if (params.has('subroute')) {
        const appId = params.get('subroute');

        if (!appId || !Number.isInteger(parseInt(appId))) {
          this.resetWorkflowAndRedirect();
          return;
        }

        this.applianceId = appId;

        this.subscriptionService.getSubscriptionDetails(+this.applianceId)
          .subscribe((r) => {
            this.subscription = Subscription.fromJson(r);
            if (!this.subscription || !this.subscription.isValid) {
              this.resetWorkflowAndRedirect();
              return;
            }
            if (this.subscription.isOneTrial || (this.subscription.isOne && this.subscription.cancelled)) {
              this.resetWorkflowAndRedirect();
              return;
            }

            if (!this.subscription.is_my && !this.m.account.currentUser.companyIsPC20Csp) {
              this.customerService.refresh();
            }
        });

        return;
      }

      this.resetWorkflowAndRedirect();

      return;
    });
  }

  onCancelPeriodSelected(event: boolean) {
    this.workflowStep = 'confirm-subscription-cancel';
    this.isSubscriptionCancellingNow = event;
  }

  async onCancelConfirmed() {
    if (!this.subscription.is_my && !this.m.account.currentUser.companyIsPC20Csp && this.subscription.isOne) {
      const contact = this.m.customer.customers
        .find(r =>  r.id === this.subscription.customer_id)
        .contacts[0];

      await firstValueFrom(this.buyService.createEmptyOffer({appliance_id: this.applianceId, partner_id: contact.id}));
      await firstValueFrom(this.buyService.addProduct('PC-CANCEL'));
      await firstValueFrom(this.buyService.confirmPartnerQuotation());

      this.workflowStep = 'partner-cancel-subscription-done';
      return;
    }

    this.subscriptionService.cancelSubscription(this.subscription, this.isSubscriptionCancellingNow).subscribe(r => {
      if (this.isSubscriptionCancellingNow) {
        this.resetWorkflowAndRedirect(true);
      } else {
        this.resetWorkflowAndRedirect();
      }
    });
  }

  resetWorkflowAndRedirect(redirectToSubscriptionsList: boolean = false) {
    this.m.workflow.resetWorkflow();

    if (this.backroute) {
      this.router.navigate(this.backroute);
      return;
    }

    if (redirectToSubscriptionsList) {
      this.router.navigate(['/']);
    } else {
      this.router.navigate(['/subscriptions', this.applianceId]);
    }
  }

  get isOne() {
    return this.subscription?.isOne ?? false;
  }
}

