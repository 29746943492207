import { Component, EventEmitter, Input, Output } from '@angular/core';
import {Subscription} from "../../subscription/subscription.model";

@Component({
    selector: 'app-tenant-migration',
    templateUrl: './tenant-migration.component.html',
    styleUrls: []
})
export class TenantMigrationComponent {
    @Input() public stepsBack = 1;
    @Input() public readonly subscription: Subscription;

    @Output() public readonly back = new EventEmitter<void>();
    @Output() public readonly continue = new EventEmitter<void>();

    onContinue() {
        this.continue.emit()
    }

    onBack() {
        this.back.emit();
    }
}


