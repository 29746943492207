import { Component, OnInit } from '@angular/core';
import { AutorunComponent } from '@myshared/autorun.component';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Model } from '../../app.model';
import {BankAccount, Company, Contact} from '../../company/company.model';
import {PaymentMethodCode} from '../../buy/buy.model';
import {TariffBankAccount, TariffFlatOffer} from '../../tariff/tariff.model';
import {BuyService} from '../../buy/buy.service';
import {TariffService} from '../../tariff/tariff.service';
import {Subscription} from '../../subscription/subscription.model';
import {SubscriptionService} from '../../subscription/subscription.service';
import {MessageService} from 'primeng/api';
import {I18NextService} from 'angular-i18next';
import {firstValueFrom} from "rxjs";
import {runInAction} from "mobx";
import {CompanyService} from "../../company/company.service";
import {Customer} from "../../customer/customer.model";

type TariffStep = 'choose-tariff'
  | 'select-contact'
  | 'delivery-date'
  | 'customer-invoice-address'
  | 'customer-payment-method'
  | 'invoice-address'
  | 'payment-method'
  | 'checkout-summary'
  | 'thank-you'
  | 'thank-you-partner';

function validateTariffStep(input: string, orderName: string): TariffStep|boolean {
  if (['choose-tariff', 'select-contact', 'delivery-date', 'invoice-address', 'payment-method'].indexOf(input) !== -1) {
    return input as TariffStep;
  }
  if (orderName && ['checkout-summary', 'thank-you', 'thank-you-partner'].indexOf(input) !== -1) {
    return input as TariffStep;
  }

  return false;
}

@Component({
    selector: 'app-workflow-tariff',
    templateUrl: './workflow-tariff.component.html',
    styleUrls: []
})
export class WorkflowTariffComponent extends AutorunComponent implements OnInit {

    public step: TariffStep;
    public company: Company;
    public tariffFlatOffer: TariffFlatOffer;
    public subscription: Subscription;
    public applianceId: string;
    public selectedCustomer: Customer;
    public selectedContactId: string;
    public paymentMethod: PaymentMethodCode;
    public orderName: string;
    public isPC20CSP: boolean;

    constructor(private router: Router,
                private m: Model,
                private route: ActivatedRoute,
                private buyService: BuyService,
                private tariffService: TariffService,
                private subscriptionService: SubscriptionService,
                private messageService: MessageService,
                private i18next: I18NextService,
                private companyService: CompanyService
    ) {
        super();

      this.orderName = '';
      this.m.buy.resetCurrentOrder();

      this.route.paramMap.subscribe((params: ParamMap) => {
          if (params.has('id')) {
              const id = params.get('id');
              if (id !== '') {
                  this.orderName = id;
                  this.buyService.refresh(this.orderName);
              }
          }
          if (params.has('subroute')) {
              const sr = params.get('subroute');

              if (!this.applianceId && +sr > 0) {
                  this.applianceId = sr;
              }

              if (this.applianceId && !this.subscription) {
                this.subscriptionService.getSubscriptionDetails(+this.applianceId).subscribe(async (r) => {
                  this.subscription = Subscription.fromJson(r);
                  this.tariffFlatOffer = new TariffFlatOffer();
                  this.tariffFlatOffer.appliance_id = +this.applianceId;

                  if (!this.subscription.tariffPlanDetail.currentPlan
                    || this.subscription.tariffPlanDetail.currentPlan === 'PC-ONE-TARIFF-TRIAL') {
                    this.cancelWorkflow();
                    return;
                  }

                  if (!!this.subscription.tariffPlanDetail.orderedPlanActivationDate) {
                    this.cancelWorkflow();
                    return;
                  }

                  if (this.subscription.isOne && (this.subscription.is_my || this.isPC20CSP)) {
                    this.setStep('choose-tariff'); // customer can change directly the plan
                  } else if (this.subscription.isOne && !this.subscription.is_my && !this.isPC20CSP) {
                    this.company = await firstValueFrom(this.companyService.getCompany(this.subscription.customer_id));
                    this.selectedCustomer = new Customer(
                      this.company.id,
                      this.company.name,
                      this.company.street,
                      this.company.street2,
                      this.company.zip,
                      this.company.city,
                      this.company.country,
                      [],
                      this.company.contacts
                    )
                    runInAction(() => this.m.customer.customers[0] = this.selectedCustomer)
                    this.setStep('select-contact'); // partner needs to choose a customer of the company first
                  } else {
                    this.cancelWorkflow();
                  }

                });
              }

              const newStep = validateTariffStep(sr, this.orderName);
              if (newStep !== false) {
                  this.setStep(newStep as TariffStep);
                  return;
              }
          }
        });
    }

    public ngOnInit() {
        if (!this.orderName && !this.applianceId) {
          this.cancelWorkflow();
          return;
        }

        this.autorun(async () => {
          this.isPC20CSP = this.m.account.currentUser.companyIsPC20Csp;
          if (!!this.orderName && !!this.m.buy.currentOrder && !this.subscription) {
            this.applianceId = this.m.buy.currentOrder.aid;
            if (+this.applianceId > 0) {
                this.subscriptionService.getSubscriptionDetails(+this.applianceId).subscribe(r => {
                  this.subscription = Subscription.fromJson(r);
                });
            }
          }

          // Partner offer
          if (!this.subscription?.is_my && !this.isPC20CSP) {
            // reload company of the customer
            if (!this.company && this.m.buy.currentOrder.name !== '') {
              this.company = this.m.buy.currentOrder.invoice_address;
              if (this.company.bankAccounts.length <= 0) {
                try {
                  this.company = await firstValueFrom(this.companyService.getCompany(this.company.id));
                } catch (e) {}
              }
            }
          }
        });

      this.paymentMethod = this.m.buy.currentOrder.payment_method ?? this.subscription.payment_method;
    }

    public cancelWorkflow() {
        this.m.workflow.resetWorkflow();
        if (!!this.applianceId) {
            this.router.navigate(['/', 'subscriptions', this.applianceId]);
        } else {
            this.router.navigate(['/', 'subscriptions']);
        }
    }

    public onBackTariffDeliveryDate() {
        this.setStep('choose-tariff');
    }

    public onContactSelected(contact: Contact) {
      this.selectedContactId = contact.id;
      this.navigateToStep( 'choose-tariff');
    }

    public onTariffSelected(tariff: string) {
        if (tariff.toUpperCase() === this.subscription.tariffPlanDetail.currentPlan.toUpperCase()) {
            this.messageService.add({severity: 'error',
              summary: this.i18next.t('chosen_current_same_tariff') as string,
              detail: this.i18next.t('chosen_current_same_tariff_details') as string
            });
            return;
        }
        this.tariffFlatOffer.tariff_plan = tariff;
        this.tariffFlatOffer.appliance_id = +this.applianceId;
        this.setStep('delivery-date');
    }

    public onSelectTariffDeliveryDate(deliveryDate: string) {
        this.tariffFlatOffer.delivery_date = deliveryDate;
        if (this.subscription.is_my || this.isPC20CSP) {
          this.setStep('invoice-address');
        } else {
          this.setStep('customer-invoice-address');
        }
    }

    public onInvoiceBack() {
      this.setStep('delivery-date');
    }

    public onInvoiceAddressFilled(company: Company) {
      this.company = company;
      this.setStep('payment-method');
    }

    public onCustomerInvoiceAddressFilled(company: Company) {
      this.companyService.update(company).subscribe(ok => {
        const bankAccounts = this.company.bankAccounts;
        this.company = company;
        this.company.bankAccounts = bankAccounts;
        this.setStep('customer-payment-method');
      });
    }

    public onPaymentBack() {
      this.setStep('invoice-address');
    }

    public onPartnerPaymentBack() {
      this.setStep('customer-invoice-address');
    }

    public async onPaymentMethodSelected(details: {bank?: BankAccount, method?: PaymentMethodCode}) {
      if (details.method === 'sepa') {
        await firstValueFrom(this.companyService.updateBankAccount(details.bank));
      }

      if (this.selectedContactId) {
        this.tariffFlatOffer.partner_id = +this.selectedContactId;
      }
      this.tariffService.createTariffOffer(this.tariffFlatOffer).subscribe(_ => {
        this.orderName = this.m.buy.currentOrder.name;
        this.navigateToStep('checkout-summary', this.orderName);
      });
    }

    public onBuy() {
        if (this.m.account.currentUser.company_id !== this.m.buy.currentOrder.invoice_address.id) {
          this.onBuyPartner();
          return;
        }

        this.onBuyCustomer();
    }

    public onSummaryBack() {
      this.applianceId = this.m.buy.currentOrder.aid;
      this.orderName = '';
      this.m.buy.resetCurrentOrder();
      this.cancelWorkflow();
    }

    private setStep(step: TariffStep) {
        this.step = step;
    }

    private navigateToStep(step: TariffStep, orderId?: string) {
        this.router.navigate(['/do', this.m.workflow.name, step, orderId ?? ''], {
            queryParamsHandling: 'preserve'
        });
    }

    private onBuyPartner() {
      this.buyService.confirmPartnerQuotation().subscribe(_ => {
        this.navigateToStep('thank-you-partner', this.orderName);
      });
    }

    private onBuyCustomer() {
      this.buyService.confirmOffer({
        name: this.subscription.name,
      }).subscribe(_ => {
        this.navigateToStep('thank-you');
        this.m.buy.resetCurrentOrder();
      });
    }

}
