import {runInAction} from 'mobx';
import {computed, observable} from '@myshared/mobx-connector';

export class WorkflowRequestModel {
  @observable name: string;

  constructor() {
    this.resetWorkflow();
  }

  public resetWorkflow() {
    runInAction(() => this.name = '');
  }

  @computed get isActivateUser(): boolean {
    return this.name === 'activate-user';
  }

  @computed get isInvite(): boolean {
    return this.name === 'invite-user';
  }

  @computed get isCancelSubscription(): boolean {
    return this.name === 'cancel-subscription';
  }

  @computed get isOneTrial(): boolean {
    return this.name === 'trial';
  }
  @computed get isOneUpgrade(): boolean {
    return this.name === 'upgrade';
  }

  @computed get isPhonenumbers(): boolean {
    return this.name === 'phonenumbers';
  }

  @computed get isTariff(): boolean {
    return this.name === 'tariff';
  }

  @computed get isTariffCancel(): boolean {
    return this.name === 'tariff-cancel';
  }

  @computed get isItemizedBilling(): boolean {
    return this.name === 'itemized-billing';
  }

  @computed get isNfrOne(): boolean {
    return this.name === 'nfr-one';
  }
  @computed get isOneTrialPartner(): boolean {
    return this.name === 'trial-partner';
  }

  @computed get inWorkflow(): boolean {
    // little js trick to check for undefined, null, empty string, etc.
    return !!this.name;
  }
}
