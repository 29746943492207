import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Model} from '../../app.model';
import {runInAction} from 'mobx';
import {SubscriptionService} from '../subscription.service';
import {Subscription} from '../subscription.model';
import {AutorunComponent} from '@myshared/autorun.component';
import {observable} from '@myshared/mobx-connector';
import {Block} from '../../phonenumber/phonenumber.model';
import {PhonenumberService} from '../../phonenumber/phonenumber.service';

@Component({
  selector: 'app-subscription-view',
  templateUrl: './subscription-view.component.html',
  styleUrls: []
})
export class SubscriptionViewComponent extends AutorunComponent implements OnInit {

  public subscription: Subscription;
  public childSubscriptions: Subscription[] = [];
  public trunks: Block[] = [];
  public hasTrunkAccess = false;
  public isPartner = false;
  public isAdmin: boolean;

  @observable private applianceId$: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private m: Model,
              private subscriptionService: SubscriptionService,
              private phonenumberService: PhonenumberService) {
    super();
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      if (params.has('applianceId')) {
        runInAction(() => {
          this.applianceId$ = params.get('applianceId');
        });
        this.subscriptionService.getSubscriptionDetails(+this.applianceId$)
          .subscribe({
            next: (r) => {
              this.subscription = Subscription.fromJson(r);
              // When we don't get a subscription, redirect
              if (!this.subscription) { this.redirect(); return; }
              this.loadBootstate();
            },
            error: () => {
              // When the subscription not exist anymore, the server will send back a 500,
              // in this case redirect
              this.redirect();
            }
          });
      } else {
        this.router.navigate(['/subscriptions']);
      }
    });

    this.autorun(() => {
      this.isAdmin = this.m.account.isMypascomAdmin;
      this.hasTrunkAccess = this.m.account.hasTrunkAccess
      this.isPartner = this.m.account.currentUser.companyIsPartner;
      if (this.subscription) {
        this.childSubscriptions = this.m.subscription.subscriptions.filter(s => s.parent_id === this.subscription.appliance_id) ?? [];
      }
    });

    this.autorun(() => {
      this.trunks = this.m.phonenumber.blocks.filter(t => {
          if (+t.appliance?.appliance_id === +(this.applianceId$)) {
            t.appliance = this.subscription;
            return true;
          }
          return false;
        }
      );
    });
    if (this.hasTrunkAccess) {
      this.phonenumberService.refresh(true);
    }
  }

  public onBack() {
    this.redirect();
  }

  public get hasPhonenumbers() {
    return this.hasTrunkAccess && this.subscription &&
      (this.subscription.isOne || this.subscription.isOneTrial) && this.trunks.length > 0;
  }

  get hasTariffDetail(): boolean {
    return !!this.subscription?.tariffPlanDetail?.currentPlan
      && this.subscription?.tariffPlanDetail?.currentPlan !== 'PC-ONE-TARIFF-TRIAL';
  }

  get showItemizedBilling(): boolean {
    return !!this.subscription?.tariffPlanDetail?.currentPlan
        && this.subscription?.tariffPlanDetail?.currentPlan !== 'PC-ONE-TARIFF-TRIAL';
  }

  public get hasTrunkCards() {
    return (this.hasTrunkAccess || this.isPartner) && this.subscription && this.subscription.isOne && (this.hasTariffDetail || this.showItemizedBilling);
  }

  public get status(): { status: string; color: any, messageBg: string } {
    const pbxStatus = this.subscription.pbxStatus;
    if (pbxStatus) {
      pbxStatus.status += '_tooltip';
    }
    return pbxStatus;
  }

  private redirect() {
    this.router.navigate(['/']);
  }

  /**
   * Load the bootstate for this given subscriptions if parent name matches
   *
   * If all subscriptions were loaded, we will get the data from subscriptions bootstate,
   * otherwise we will load this data with a single [subscription] array item.
   * @private
   */
  private loadBootstate() {
    if (!this.subscriptionService.isBoostateForParentNameWhitelisted(this.subscription.parent_name)) return;
    if (this.m.subscription.subscriptionBoostates.size > 0) {
      this.subscription.setBootstate(this.m.subscription.findBootstate(this.subscription));
    } else {
      this.subscriptionService.getSubscriptionsBootstate([this.subscription]).subscribe(r => {
        this.subscription.setBootstate(r[this.subscription.parent_name][0].bootstate);
      }, error => {
        this.subscription.setBootstate('unknown');
      });
    }
  }
}
