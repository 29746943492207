import {Injectable} from '@angular/core';
import {Model} from '../app.model';
import {Base64 } from 'js-base64';
import {action} from '@myshared/mobx-connector';
import {HttpClient} from "@angular/common/http";

@Injectable()
export class WorkflowService {

  constructor(private m: Model, private http: HttpClient) {
  }

  @action public setWorkflow(workflowName: string): void {
    if (['csp', 'cancel-subscription', 'invite-user',
      'upgrade', 'phonenumbers', 'itemized-billing',
      'tariff', 'tariff-cancel', 'nfr-one', 'trial-partner'
    ].indexOf(workflowName) !== -1) {
      this.setWorkflowName(workflowName);
    }
  }

  parseWorkflowRequest(b64: string) {
    return JSON.parse(Base64.decode(b64));
  }

  @action public setWorkflowName(name: string) {
    this.m.workflow.name = name;
  }
}
