import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Model} from '../../app.model';
import {AutorunComponent} from '@myshared/autorun.component';
import {Subscription} from '../../subscription/subscription.model';
import {
  PC_ONE_ENDPOINTS,
  PC_ONE_PHONE_NUMBERS,
  PC_ONE_TRUNK_LINES,
  ProductPriceDiscount
} from '../select-product/product.model';
import {Customer} from "../../customer/customer.model";

type cuErrorType = ('' | 'max' | 'min' | 'same');

@Component({
  selector: 'app-upgrade-one',
  templateUrl: './upgrade-one.component.html',
})
export class UpgradeOneComponent extends AutorunComponent implements OnInit {
  public readonly cuMax: number = 1500;
  public readonly cuMin = 4; // cannot be readonly because we need to change this for upgrade

  public cu = this.cuMin;
  private oldCu = this.cu; // only old Value before change

  private readonly trunkLines: number = PC_ONE_TRUNK_LINES;
  private readonly endpoints: number = PC_ONE_ENDPOINTS;
  private readonly phoneNumbers: number = PC_ONE_PHONE_NUMBERS;

  public calculatedPrice;

  public cuError: cuErrorType = '';
  public isPartner: boolean;
  public hasTrunkAccess: boolean;
  public subscriptionUsers: number;

  @Input() subscription: Subscription;
  @Input() readonly productPrice: number;
  @Input() readonly customer?: Customer;
  @Input() readonly productDiscount: ProductPriceDiscount[];
  @Output() public readonly next = new EventEmitter<number>();
  @Output() public readonly back = new EventEmitter();

  constructor(private http: HttpClient,
              private m: Model) {
    super();
  }

  ngOnInit() {
    this.calculatedPrice = this.productPrice;
    this.subscriptionUsers = +this.subscription.users;
    if (this.subscriptionUsers > 4 && this.subscription.isOne) {
      this.cu = this.subscriptionUsers;
      this.oldCu = this.subscriptionUsers;
    }

    this.calculatedPrice = this.calculatePrice();

    this.autorun(() => {
      this.isPartner = this.m.account.currentUser.companyIsPartner;
      this.hasTrunkAccess = this.m.account.hasTrunkAccess;
    });
  }

  get product() {
    return {
      highlighted: true,
      recommended: false,
      name: 'pascom_one_product_name',
      description: 'pascom_one_product_description',
      billingPeriod: 'pascom_one_billing_period_text',
      startingPrice: 9.90,
      features: [
        { name: 'pascom_one_feature_type', highlighted: false },
        { name: 'pascom_one_feature_payment', highlighted: false },
        { name: 'pascom_one_feature_users', highlighted: false },
        { name: 'pascom_one_feature_trunking', highlighted: false },
        { name: 'pascom_one_price_german_landline', highlighted: false },
        { name: 'pascom_one_price_german_mobile', highlighted: false },
      ],
      buttonText: 'start_one_trial_button',
      buttonHref: '/do/trial',
    };
  }

  onNext() {
    this.next.emit(this.cu);
  }

  public onBack() {
    this.back.emit();
  }

  public onCuChanged(value) {
    this.calculatedPrice = this.calculatePrice();
    this.cuError = '';
    if (this.oldCu === this.cuMin && value === this.oldCu) {
      this.cuError = 'min';
    }
    if (this.oldCu === this.cuMax && value === this.oldCu) {
      this.cuError = 'max';
    }

    this.oldCu = value;
  }

  public onCuBlur(value) {
    if (this.cu === this.cuMin || this.cu === this.cuMax) {
      this.cuError = '';
    }
  }

  public get calculatedSumPrice() {
    return this.calculatedPrice * this.validatedCu;
  }

  public get calculatedCuPrice() {
    return this.calculatedPrice;
  }

  // cu = 20
  // So if someone buys 20 CU he gets 10 CU for 9,90 € and 10 CU for 8,91 €.
  // The average CU price in this case is (10*9,90 + 10*8,91) / 20 = 9,41
  private calculatePrice() {
    const prices = this.productDiscount;
    const cu = this.cu;
    let cuLeft = cu;
    let totalPrice = 0;

    for (const p of prices) {
      // calculate the discount price
      const discount = (p.discount * this.productPrice) / 100;
      const calculatedDiscountPrice = Math.round((this.productPrice - discount) * 10 * 10) / 100;

      // look how many cus left and the actual price qty
      if (cuLeft > p.quantity) {
        totalPrice += calculatedDiscountPrice * p.quantity;
        cuLeft -= p.quantity;
      } else {
        totalPrice += calculatedDiscountPrice * cuLeft;
        cuLeft = 0;
        const median = totalPrice / cu;
        return Math.round(median * 10 * 10) / 100;
      }
    }
  }

  public get calculatedProduct() {
    let calculatedProducts = [{ translation: 'upgrade_one_price_endpoints', value: this.endpoints * this.validatedCu }];
    if (this.canSeeTrunk) {
      calculatedProducts = [
        ...calculatedProducts,
        { translation: 'upgrade_one_price_trunklines', value: this.trunkLines * this.validatedCu },
        { translation: 'upgrade_one_price_phonenumbers', value: this.phoneNumbers * this.validatedCu }
      ];
    }

    return calculatedProducts;
  }

  public get translationBtnText() {
    if (this.subscription.isOne) {
      if (this.subscriptionUsers < this.cu) {
        return 'upgrade_users';
      }
      if (this.subscriptionUsers > this.cu) {
        return 'downgrade_users';
      }
    }

    return 'select_users';
  }

  private get canSeeTrunk() {
    if (this.isPartner && this.customer && !this.subscription.is_my) {
      return this.customer.hasTrunkAccess;
    }

    return this.hasTrunkAccess;
  }

  private get validatedCu() {
    if (this.cu < this.cuMin) {
      return this.cuMin;
    } else if (this.cu > this.cuMax) {
      return this.cuMax;
    } else {
      return this.cu;
    }
  }
}
