import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AutorunComponent} from '@myshared/autorun.component';
import {Subscription} from '../../subscription/subscription.model';
import {action, observable} from '@myshared/mobx-connector';
import {runInAction} from 'mobx';
import {SubscriptionService} from '../../subscription/subscription.service';
import {filterText} from '@myshared/utils';
import {Model} from '../../app.model';

@Component({
  selector: 'app-select-one-subscription',
  templateUrl: './select-one-subscription.component.html'
})
export class SelectOneSubscriptionComponent extends AutorunComponent implements OnInit {

  public subscriptions: Subscription[];
  @observable private filter$: string;

  @Output() selected: EventEmitter<string> = new EventEmitter<string>();
  @Output() back: EventEmitter<void> = new EventEmitter<void>();

  @Input() historyBackSteps = 1;

  constructor(private subscriptionService: SubscriptionService,
              private m: Model) {
    super();
  }

  ngOnInit() {
    // 'DE' is to filter for only german companies since we only sell numbers in Germany
    // could be handled with an enum-like class and or list if we expand the countries we sell numbers in

    this.subscriptionService.refresh(true);
    runInAction(() => {
      this.filter$ = '';
      // setting Array empty to show no subscriptions while loading instead of all (even those not allowed)
      this.m.subscription.subscriptions = [];
    });

    this.autorun(() => {
      this.subscriptions = filterText(this.m.subscription.subscriptions
        .filter(l => ((l.isOne || l.isOneTrial) && l.isValid && l.trunkEnvironment.hasTrunkAccess)) , this.filter$);
    });


  }

  @action
  doFilter(filter: string) {
    this.filter$ = filter;
  }

  select(applianceId) {
    this.selected.emit(applianceId);
  }

  doBack() {
    this.back.emit();
  }
}
