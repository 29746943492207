import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Model} from "../../../app.model";
import {firstValueFrom} from "rxjs";
import {MessageService} from "primeng/api";
import {I18NextService} from "angular-i18next";
import {WorkflowPartnerService} from "../workflow-partner.service";
import {WorkflowAddTrialPhonenumberBaseComponent} from '../../workflow-add-trial-phonenumber/workflow-add-trial-phonenumber-base.component';
import {runInAction} from 'mobx';
import { SubscriptionService } from 'src/app/subscription/subscription.service';

@Component({
  selector: 'app-workflow-add-trial-phonenumber',
  templateUrl: '../../workflow-add-trial-phonenumber/workflow-add-trial-phonenumber.component.html'
})
export class WorkflowPartnerAddTrialPhonenumberComponent extends WorkflowAddTrialPhonenumberBaseComponent implements OnInit, OnDestroy {

  constructor(
    protected route: ActivatedRoute,
    protected m: Model,
    protected router: Router,
    protected i18next: I18NextService,
    protected messageService: MessageService,
    protected partnerService: WorkflowPartnerService,
    protected subscriptionService: SubscriptionService,
  ) {
    super(m, router, route, subscriptionService);
  }

  protected async createOrder() {
    try {
      const aid = this.route.snapshot.queryParamMap.get('aid');
      const phonenumber = await firstValueFrom(this.partnerService.oneTrialAddPhonenumber(aid));

      runInAction(() => {
        this.trialPhoneNumber.phonenumber = phonenumber.phonenumber;
      });

      this.navigateToStep(['completed'], true);

    } catch (e) {
      this.messageService.add({
        severity: 'error',
        summary: this.i18next.t('error') as string,
        detail: this.i18next.t('error_trial_create_sale_order_partner') as string
      });
      throw e;
    }
  }

  protected navigateToStep(url: any[], replace = false) {
    this.router.navigate(['/do', 'partner', 'trial-phonenumber', ...url], {
      replaceUrl: replace,
      queryParamsHandling: 'merge',
      preserveFragment: true,
      queryParams: {
        so: this.model?.saleOrder?.name ?? undefined,
      },
    });
  }
}
