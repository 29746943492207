import {Component, Input, OnInit} from '@angular/core';
import {AutorunComponent} from '@myshared/autorun.component';
import {Model} from '../../app.model';
import {BuyService} from '../buy.service';
import {SaleOrder} from '../buy.model';
import {SubscriptionService} from '../../subscription/subscription.service';

@Component({
  selector: 'app-buy-thankyou-partner',
  templateUrl: './buy-thankyou-partner.component.html'
})
export class BuyThankyouPartnerComponent extends AutorunComponent implements OnInit {

  @Input() orderName: string;

  public order: SaleOrder;

  constructor(private m: Model,
              private buyService: BuyService,
              private subscriptionService: SubscriptionService,
  ) {  super(); }

  ngOnInit() {
    this.autorun(() => {
      this.order = this.m.buy.currentOrder;
    });
    this.buyService.refresh(this.orderName);
    this.subscriptionService.refresh(true);
  }

  public onOpenCustomerOrders() {
    this.m.workflow.resetWorkflow();
    window.location.replace('/orders/quotations');
  }

  public get customerEmail(): string {
    return this.order.contact.email;
  }

}
