import {
  workflowEndcustomerOneUpgradeCuRouting
} from "./workflow-endcustomer-one-upgrade-cu/workflow-endcustomer-one-upgrade-cu.routing";
import {
  workflowEndcustomerOneDowngradeCuRouting
} from "./workflow-endcustomer-one-downgrade-cu/workflow-endcustomer-one-downgrade-cu.routing";
import {
  workflowEndcustomerOneUpgradeSlaRouting
} from "./workflow-endcustomer-one-upgrade-sla/workflow-endcustomer-one-upgrade-sla.routing";
import {
  workflowEndcustomerOneDowngradeSlaRouting
} from "./workflow-endcustomer-one-downgrade-sla/workflow-endcustomer-one-downgrade-sla.routing";
import {
  workflowEndcustomerOneTrialCreateRouting
} from "./workflow-endcustomer-one-trial-create/workflow-endcustomer-one-trial-create.routing";
import {
  workflowEndcustomerAddTrialPhonenumberRouting
} from "./workflow-endcustomer-add-trial-phonenumber/workflow-endcustomer-add-trial-phonenumber.routing";

export const workflowEndcustomerRouting = [
  ...workflowEndcustomerOneUpgradeCuRouting,
  ...workflowEndcustomerOneUpgradeSlaRouting,
  ...workflowEndcustomerOneDowngradeCuRouting,
  ...workflowEndcustomerOneDowngradeSlaRouting,
  ...workflowEndcustomerOneTrialCreateRouting,
  ...workflowEndcustomerAddTrialPhonenumberRouting,
]