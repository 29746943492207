import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from '../subscription.model';
import {AutorunComponent} from "@myshared/autorun.component";
import {Model} from "../../app.model";
import {firstValueFrom} from "rxjs";
import {observable, runInAction} from "mobx";
import {CompanyService} from "../../company/company.service";
import {Company} from "../../company/company.model";

@Component({
  selector: 'app-subscription-cards',
  templateUrl: './subscription-cards.component.html',
})
export class SubscriptionCardsComponent extends AutorunComponent implements OnInit {

  @Input() public subscription: Subscription;
  public isAdmin: boolean;
  public hasTrunkAcccess: boolean;

  @observable public company: Company;

  constructor(private m: Model, private companyService: CompanyService) { super(); }

  async ngOnInit() {
    this.autorun(async () => {
      this.isAdmin = this.m.account.isMypascomAdmin;
      this.hasTrunkAcccess = this.m.account.hasTrunkAccess;
      if (this.subscription && !this.subscription.is_my && !this.company) {
        await this.reloadCompany(this.subscription.customer_id);
      }
    });
  }

  public get canSeeTrunk() {
    return ((this.subscription.is_my && this.hasTrunkAcccess) || (!this.subscription.is_my && this.company && this.company.hasTrunkAccess));
  }

  private async reloadCompany(companyId: string) {
    // reload company of the customer
    if (!this.company) {
      let company = await firstValueFrom(this.companyService.getCompany(companyId));
      runInAction(() => {
        this.company = company;
      });
    }
  }

}
