import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Model } from '../../app.model';
import {BuyService, CloudDomain, UpgradeOfferData} from '../../buy/buy.service';
import { Subscription } from '../../subscription/subscription.model';
import { SubscriptionService } from '../../subscription/subscription.service';
import { MessageService } from 'primeng/api';
import { I18NextService } from 'angular-i18next';
import { HttpClient } from '@angular/common/http';
import { ProductPriceDiscount, SlaProduct, Product } from '../../buy/select-product/product.model';
import { CustomerService } from 'src/app/customer/customer.service';
import { AutorunComponent } from '@myshared/autorun.component';
import { Customer } from 'src/app/customer/customer.model';
import { CompanyService } from "../../company/company.service";
import { BankAccount, Company, Contact } from "../../company/company.model";
import { firstValueFrom } from 'rxjs';
import { PaymentMethodCode } from "../../buy/buy.model";
import { observable, runInAction } from "mobx";
import {AuthService} from "../../auth/auth.service";

type BuyUpgradeStep =
  | 'select-contact'
  | 'creating-offer'
  | 'select-upgrade'
  | 'select-sla'
  | 'customer-invoice-address'
  | 'customer-payment-method'
  | 'invoice-address'
  | 'payment-method'
  | 'checkout-summary'
  | 'thank-you-partner'
  | 'thank-you'
  | 'tenant-migration';

function validateBuyStep(input: string, orderName: string): BuyUpgradeStep | boolean {
  if (['select-upgrade', 'select-sla', 'select-contact', 'tenant-migration'].indexOf(input) !== -1) {
    return input as BuyUpgradeStep;
  }
  if (orderName &&
    ['customer-invoice-address', 'customer-payment-method', 'invoice-address', 'payment-method',
      'checkout-summary', 'thank-you', 'thank-you-partner']
      .indexOf(input) !== -1) {
    return input as BuyUpgradeStep;
  }
  return false;
}

const TENANT_MIGRATION_STORE = 'tenant_migration';

@Component({
  selector: 'app-workflow-upgrade-one',
  templateUrl: './workflow-upgrade-one.component.html',
})
export class WorkflowUpgradeOneComponent extends AutorunComponent implements OnInit {
  public orderName: string;
  public applianceId = '';
  public selectedCu = 0;
  public selectedSLA = '';

  public step: BuyUpgradeStep;
  @observable public subscription: Subscription;

  public slaProducts: SlaProduct[];
  public pcOnePrice: number;
  public pcOneDiscount: ProductPriceDiscount[];
  public selectedCustomer: Customer | Company;
  public selectedContactId: string;
  public selectedDomain: string;
  public isPC20CSP: boolean;
  public paymentMethod: PaymentMethodCode;

  @observable public company: Company;

  constructor(
    private m: Model,
    private route: ActivatedRoute,
    private router: Router,
    private buyService: BuyService,
    private subscriptionService: SubscriptionService,
    private messageService: MessageService,
    private i18next: I18NextService,
    private http: HttpClient,
    private customerService: CustomerService,
    private companyService: CompanyService,
    private authService: AuthService
  ) {
    super();
    this.m.buy.resetCurrentOrder();
  }

  async ngOnInit() {
    const store = this.tenantMigrationStore;
    if (store?.subscription) {
      const sub = Subscription.fromJson(store.subscription);
      // If we're on the same tenant as the saved subscription, we don't need it anymore.
      if (!sub.storedTenantId || sub.storedTenantId === this.getStoredTenantId()) {
        this.clearTenantMigrationStore();
      }
    }

    this.autorun(async () => {
      // reload subscription if we have an offer but no subscription
      if (!this.subscription && this.m.buy.currentOrder.name !== '') {
        try {
          await this.getSubscription(+this.m.buy.currentOrder.aid);
        } catch (e) { }
      }

      // Partner offer
      if (this.subscription && !this.subscription.is_my) {
        // check if partner is pascom 20 csp partner
        this.isPC20CSP = this.m.account.currentUser.companyIsPC20Csp;
        if (this.isTenantMigration && !this.isPC20CSP) {
          const store = this.tenantMigrationStore;
          if (store?.subscription && store?.companyId) {
            const company = await firstValueFrom(this.companyService.getCompany(store.companyId));
            runInAction(() => {
              this.selectedCustomer = company;
              this.company = company;
            })
            if (!this.orderName) {
              this.setStep('select-contact');
            }
          } else {
            if (!this.orderName) {
              this.setStep('tenant-migration');
            }
          }
        } else if (this.subscription && !this.subscription?.is_my) {
          // find the customer for this subscription
          if (!this.isPC20CSP) {
            this.findAndSelectContact();
            await this.reloadCompany();
          }
        }
      }

      this.paymentMethod = this.m.buy.currentOrder?.payment_method ?? this.subscription?.payment_method;
    });

    this.applianceId = this.route.snapshot.queryParamMap.get('aid') ?? undefined

    // Handle url parameter change
    this.route.paramMap.subscribe(async (params: ParamMap) => {
      try {
        if (params.has('id')) {
          const id = params.get('id');
          this.handleOffer(id);
        }

        if (params.has('subroute')) {
          const sr = params.get('subroute');
          if (!this.applianceId)
            this.applianceId = +sr > 0 ? sr : undefined;

          await this.handleSubroute(params);

          const newStep = validateBuyStep(sr, this.orderName ?? '');
          if (newStep !== false) {
            this.setStep(newStep as BuyUpgradeStep);
            return;
          }
        }
      } catch (e) { }
    });
  }

  private handleOffer(id: string) {
    if (id !== '') {
      this.orderName = id;
      this.buyService.refresh(this.orderName);
    }
  }

  private findAndSelectContact() {
    if (this.m.customer.customers.length > 0) {
      this.selectedCustomer = this.m.customer.customers.find(r => r.id === this.subscription?.customer_id);
      if (this.selectedCustomer && this.m.buy.currentOrder.name === '') {
        this.setStep('select-contact');
      }
    }
  }

  private async handleSubroute(params: ParamMap) {
    try {
      if (this.applianceId && this.m.workflow.isOneUpgrade) {
        if (!params.has('id')) {
          this.router.navigate(['/do', this.m.workflow.name, this.applianceId, ''], { queryParamsHandling: 'preserve' });
          return;
        }

        await this.getSubscription(+this.applianceId);
        await this.getProducts();

        if (this.subscription.is_my || this.isPC20CSP) {
          if (this.m.workflow.isOneUpgrade) {
            this.setStep('select-upgrade');
          }
        } else {
          this.getCompany();
        }
      }
    } catch (e) { }
  }

  private getCompany() {
    this.customerService.refresh(true);
  }

  private setStep(step: BuyUpgradeStep) {
    this.step = step;
  }

  private navigateToStep(step: BuyUpgradeStep, orderId?: string) {
    this.router.navigate(['/do', this.m.workflow.name, step, orderId ?? ''], { queryParamsHandling: 'preserve' });
  }

  public onProductSelected(cu: number) {
    this.selectedCu = cu;
    this.buyService.selectedProduct = 'PC-ONE';
    this.setStep('select-sla');
  }

  public onBackUpgradeOne() {
    if (this.subscription.is_my || this.isPC20CSP) {
      this.m.workflow.resetWorkflow();

      this.cancelWorkflow();
      return;
    }

    this.setStep('select-contact');
  }

  public onBackMigrateCloud() {
    this.m.workflow.resetWorkflow();
    this.cancelWorkflow();
  }

  public onSLAProductSelected(sla: string) {
    this.selectedSLA = sla;
    this.selectProductAndCreateOffer();
  }

  public onSLAProductBack() {
    this.setStep('select-upgrade');
  }

  /**
   * Partner is filling invoice address for his customer
   * @param company
   */
  public async onCustomerInvoiceAddressFilled(company: Company) {
    this.companyService.update(company).subscribe((company: Company) => {
      if (this.isPC20CSP) {
        runInAction(() => { this.company = company; })
      }
      this.navigateToStep('customer-payment-method', this.orderName);
    });
  }

  /**
   * Partner is filling payment details for his customer
   * @param bank
   */
  public async onCustomerPaymentMethodSelected({ method, bank }: { method?: PaymentMethodCode; bank?: BankAccount }) {
    try {
      if (method === 'sepa') {
        bank.partner_id = +this.company.id;
        await firstValueFrom(this.companyService.updateBankAccount(bank));
        this.company.bankAccounts[0] = bank
      }

      this.recalculateOfferCheckoutSummary();
    } catch (e) {}
  }

  public onInvoiceAddressFilled(company: Company) {
    this.navigateToStep('payment-method', this.orderName);
  }

  public async onPaymentMethodSelected() {
    this.recalculateOfferCheckoutSummary();
  }

  public onBuy() {
    if (this.m.account.currentUser.company_id !== this.m.buy.currentOrder.invoice_address.id && !this.isPC20CSP) {
      this.onBuyPartner();
      return;
    }

    this.onBuyCspOrCustomer();
  }

  public onContactSelected(contact: Contact) {
    if (!contact.id) {
      window.location.reload();
      return;
    }
    this.selectedContactId = contact.id;

    this.setStep('select-upgrade');
  }

  public onSelectContactBack() {
    this.cancelWorkflow();
  }

  public onTenantMigrationBack() {
    this.cancelWorkflow();
  }

  public onTenantMigrationNext() {
    this.authService.tenantMigration(+this.subscription.customer_id).subscribe((company: Company) => {
      this.saveTenantMigrationStore({ sub: this.subscription, companyId: company.id })
      this.authService.switchTenant(company.tenantId);
    });
  }

  recalculateOfferCheckoutSummary() {
    if (this.isPC20CSP) {
      this.buyService.getOfferRecalculate(this.orderName).subscribe(r => {
        this.navigateToStep('checkout-summary', this.orderName);
      });
    } else {
      this.navigateToStep('checkout-summary', this.orderName);
    }
  }

  private async selectProductAndCreateOffer() {
    const product = this.buyService.selectedProduct;
    const slaProduct = this.selectedSLA;
    let cu = null;
    if (!!this.selectedCu && this.selectedCu > 0) {
      cu = this.selectedCu;
    }

    if (this.subscription.isOne && cu === +this.subscription.users
      && slaProduct === this.subscription.sla_product) {
      this.messageService.add({
        severity: 'error',
        summary: this.i18next.t('product_no_upgrade_option_selected') as string,
        detail: this.i18next.t('product_no_upgrade_option_selected_description') as string
      });
      return;
    }

    this.setStep('creating-offer');
    const offer = {
      appliance_id: this.applianceId,
      partner_id: this.selectedContactId ?? undefined,
      sla_product: slaProduct,
      cu
    }

    try {
      if (this.isTenantMigration) {
        await firstValueFrom(this.buyService.createUpgradePascomOneCrossTenantOffer(offer));
        this.orderName = this.m.buy.currentOrder.name;
        if (this.subscription.is_my) {
          this.navigateToStep('invoice-address', this.orderName);
        } else {
          this.navigateToStep('customer-invoice-address', this.orderName);
        }
      } else {
        await firstValueFrom(this.buyService.createUpgradePascomOneOffer(offer));
        this.orderName = this.m.buy.currentOrder.name;

        this.buyService.changeProduct(product, slaProduct, cu).subscribe({
          next: (r: any) => {
            if (this.subscription.is_my) {
              this.navigateToStep('invoice-address', this.orderName);
            } else {
              this.navigateToStep('customer-invoice-address', this.orderName);
            }
          },
          error: (e: any) => {
            this.cancelWorkflow()
          }
        })
      }
    } catch (e) {
      this.cancelWorkflow();
    }
  }

  public get isTenantMigration() {
    return this.subscription && this.subscription.tenantMigrationEligible;
  }

  public onSummaryBack() {
    if(this.paymentMethod === 'wire_transfer') {
      history.go(-2);
    } else {
      history.back();
    }
  }

  private onBuyPartner() {
    this.buyService.confirmPartnerQuotation().subscribe(confirm => {
      if (this.isPC20CSP) {
        this.navigateToStep('thank-you', confirm.name);
      } else {
        this.navigateToStep('thank-you-partner', confirm.name);
      }
    });
  }

  private onBuyCspOrCustomer() {
    const data: UpgradeOfferData = {
      cspdomain: this.isPC20CSP ? this.selectedDomain : undefined
    }
    this.buyService.confirmUpgrade(data).subscribe(confirm => {
      this.navigateToStep('thank-you', confirm.name);
    });
  }

  private cancelWorkflow() {
    this.m.workflow.resetWorkflow();

    const store = this.tenantMigrationStore;
    if (store?.subscription) {
      this.clearTenantMigrationStore();
      // We need to reset the appliance id, because we do not have it in the current tenant
      // This avoids spelling errors after resetting the workflow.
      this.applianceId = undefined;
    }

    if (this.applianceId) {
      this.router.navigate(['/', 'subscriptions', this.applianceId]);
    } else {
      this.router.navigate(['/', 'subscriptions']);
    }
  }

  private async getSubscription(aid: number) {
    try {
      const store= this.tenantMigrationStore;
      if (store?.subscription) {
        const subscription = Subscription.fromJson(store.subscription);
        if (subscription.tenantMigrationEligible && +subscription.appliance_id === aid) {
          runInAction(() => {
            this.subscription = subscription;
          })
          return Promise.resolve(this.subscription);
        }
      }

      const subscription = await firstValueFrom(this.subscriptionService.getSubscriptionDetails(aid));
      runInAction(() => {
        this.subscription = Subscription.fromJson(subscription);
      })

      return Promise.resolve(this.subscription);
    } catch (e) {
      this.cancelWorkflow();
    }
  }

  private async reloadCompany() {
    // reload company of the customer
    if (!this.company && this.m.buy.currentOrder.name !== '') {
      let company = this.m.buy.currentOrder.invoice_address;
      if (company.bankAccounts.length <= 0) {
        try {
          company = await firstValueFrom(this.companyService.getCompany(company.id));
        } catch (e) { }
      }
      runInAction(() => {
        this.company = company;
      });
    }
  }

  private async getProducts() {
    const cloudProducts =
        await firstValueFrom(
            this.http.get<{ sla, pc_one_price, pc_one_discount, free, basic, premium }>('/services/products/cloud')
        );

    this.slaProducts = cloudProducts.sla.map(r => SlaProduct.fromJson(r));
    this.pcOnePrice = cloudProducts.pc_one_price || 0;
    this.pcOneDiscount = cloudProducts.pc_one_discount.map(r => ProductPriceDiscount.fromJson(r));
  }

  /**
   * Handles saving the tenant migration store
   *
   * We need to save the subscription after the page refresh. Also, we need to keep it in the store because AT tenant
   * cannot access DE tenant. But we need this information to go through the tenant migration process.
   *
   * @param data
   * @private
   */
  private saveTenantMigrationStore(data: {sub?: Subscription, companyId?: string}) {
    const store = sessionStorage.getItem(TENANT_MIGRATION_STORE);
    const tenantMigrationStore = JSON.parse(store) ?? {};

    let write = false;

    if (data.sub) {
      data.sub.storedTenantId = this.getStoredTenantId();
      data.sub.isLocalStored = true;
      tenantMigrationStore.subscription = JSON.stringify(data.sub);
      write = true;
    }

    if (data.companyId) {
      tenantMigrationStore.companyId = data.companyId;
      write = true;
    }


    if (write) {
      tenantMigrationStore.time = Date.now().toString();
      sessionStorage.setItem(TENANT_MIGRATION_STORE, JSON.stringify(tenantMigrationStore));
    }
  }

  /**
   * Get the tenant migration information out of the store
   *
   * @private
   */
  private get tenantMigrationStore(): {companyId: string, subscription: any} {
    const store = sessionStorage.getItem(TENANT_MIGRATION_STORE);
    if (!store) return;

    const json = JSON.parse(store);

    return {
      companyId: json.companyId ?? undefined,
      subscription: json.subscription ? JSON.parse(json.subscription) : undefined
    }

  }

  private getStoredTenantId() {
    return this.authService.getTenantIdFromStorage();
  }

  private clearTenantMigrationStore() {
    sessionStorage.removeItem(TENANT_MIGRATION_STORE);
  }
}
