<app-content-header
  [backLabel]="backLabel"
  [historyBack]="historyBack"
  (backEvent)="onBack()"
  [hint]="'checkout_summary_hint' | i18next"
  [hideBackButton]="hideBackBtn"
  [headline]="'checkout_summary_headline' | i18next"></app-content-header>

<div class="card card-nb mt-2" *ngIf="saleOrder">
  <div class="card-body">
    <div class="d-flex flex-column flex-md-row justify-content-start">
      <div class="card card-gray card-nb card-mw-300">
        <div class="card-body">
          <div class="row">
            <div class="col highlighted">
              <div class="card-h1">{{ 'checkout_summary_invoice_address' | i18next }}</div>
              <div>{{name}}</div>
              <div>{{street}}</div>
              <div>{{street2}}</div>
              <div>{{zipAndCity}}</div>
              <div>{{country}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="d-flex flex-column justify-content-start w-100 mb-3">
      <div class="mb-3 pr-3"><i class="fa red fa-cloud"></i><span>&nbsp;{{ 'subscription_type_cloud' | i18next }}</span></div>
      <div class="mr-1"><i class="fa fa-user red"></i><span>&nbsp;{{ 'checkout_summary_payment_method' | i18next }}&nbsp;{{ paymentMethod }}</span></div>
      <div *ngIf="maskedIban">{{ 'summary_payment_details' | i18next: { iban: maskedIban } }}</div>
    </div>

    <h3 class="h3">{{ 'checkout_summary_fixed_monthly_payments' | i18next }}</h3>

    <table class="table w-100 table-borderless d-none d-lg-table">
      <thead class="border-bottom border-dark">
      <tr>
        <th>{{ 'checkout_summary_description' | i18next }}</th>
        <th>{{ 'checkout_summary_quantity' | i18next }}</th>
        <th>{{ 'checkout_summary_unit_price' | i18next }}</th>
        <th *ngIf="hasDiscount">{{ 'checkout_summary_discount' | i18next }}</th>
        <th>{{ 'checkout_summary_tax' | i18next }}</th>
        <th>{{ 'checkout_summary_total' | i18next }}</th>
      </tr>
      </thead>
      <ng-container *ngFor="let line of saleOrder.lines">
        <tr *ngIf="line.product !== PC_CREDIT" class="border-bottom">
          <td>{{line.name}}</td>
          <td>{{line.product_uom_qty}}</td>
          <td style="min-width: 90px;">{{line.price_unit | localized_currency}}</td>
          <td *ngIf="hasDiscount">{{line.discount}}%</td>
          <td>
            <span>{{line.tax}}%</span>
          </td>
          <td style="min-width: 90px;">{{line.price_subtotal | localized_currency}}</td>
        </tr>
      </ng-container>
      <tr>
        <td [attr.colspan]="hasDiscount ? 3 : 2">{{ 'checkout_summary_monthly_renewed' | i18next }}</td>
        <td>{{ 'checkout_summary_subtotal' | i18next }}</td>
        <td colspan="2">{{saleOrder.amount_untaxed | localized_currency}}</td>
      </tr>
      <tr>
        <td [attr.colspan]="hasDiscount ? 3 : 2">&nbsp;</td>
        <td>{{'checkout_summary_tax' | i18next }}</td>
        <td colspan="2">{{saleOrder.amount_tax | localized_currency}}</td>
      </tr>
      <tr>
        <td [attr.colspan]="hasDiscount ? 3 : 2">&nbsp;</td>
        <td class="font-weight-bold">{{ 'checkout_summary_monthly_total' | i18next }}</td>
        <td colspan="2" class="font-weight-bold text-nowrap">{{saleOrder.amount_total | localized_currency}}</td>
      </tr>
    </table>

    <div class="d-flex flex-column d-lg-none mb-3">
      <div class="border-bottom" *ngFor="let line of saleOrder.lines">
          <div *ngIf="line.product !== PC_CREDIT">
            <div class="p-2 font-weight-bold">{{line.name}}</div>
            <div class="d-flex flex-row">
              <div class="p-2">{{ 'checkout_summary_quantity' | i18next }}</div>
              <div class="p-2 ml-auto">{{line.product_uom_qty}}</div>
            </div>
            <div class="d-flex flex-row">
              <div class="p-2">{{ 'checkout_summary_unit_price' | i18next }}</div>
              <div class="p-2 ml-auto">{{line.price_unit | localized_currency}}</div>
            </div>
            <div *ngIf="hasDiscount" class="d-flex flex-row">
              <div class="p-2">{{ 'checkout_summary_discount' | i18next }}</div>
              <div class="p-2 ml-auto">{{line.discount}}%</div>
            </div>
            <div class="d-flex flex-row">
              <div class="p-2">{{ 'checkout_summary_tax' | i18next }}</div>
              <div class="p-2 ml-auto">
                <span>{{line.tax}}%</span>
              </div>
            </div>
            <div class="d-flex flex-row">
              <div class="p-2">{{ 'checkout_summary_subtotal' | i18next }}</div>
              <div class="p-2 ml-auto">{{line.price_subtotal | localized_currency}}</div>
            </div>
          </div>
      </div>
      <div class="p-2">{{ 'checkout_summary_monthly_renewed' | i18next }}</div>
      <div class="d-flex flex-row justify-content-end">
        <div class="p-2">{{ 'checkout_summary_subtotal' | i18next }}</div>
        <div class="p-2">{{ saleOrder.amount_untaxed | localized_currency }}</div>
      </div>
      <div class="d-flex flex-row justify-content-end">
        <div class="p-2">{{ 'checkout_summary_tax' | i18next }}</div>
        <div class="p-2">{{ saleOrder.amount_tax | localized_currency }}</div>
      </div>
      <div class="d-flex flex-row justify-content-end">
        <div class="p-2 font-weight-bold">{{ 'checkout_summary_monthly_total' | i18next }}</div>
        <div class="p-2 font-weight-bold">{{ saleOrder.amount_total | localized_currency }}</div>
      </div>
    </div>

    <h3 class="h3">{{ 'checkout_summary_dynamic_monthly_payments' | i18next }}</h3>

    <div [innerHtml]="'checkout_summary_download_country_pricelist' | i18next:
    {country: countryTranslationCode}"></div>
    <div [innerHtml]="'summary_services_country_description' | i18next:
    {country: countryTranslationCode}"></div>

    <div class="my-3" *ngIf="pcCredit && pcCredit.price_unit > 0">
      <h3 class="h3">{{ 'Credit' }}</h3>
      <p *ngIf="pcCredit.price_unit > 0"
         [innerHTML]="'checkout_summary_pc_credit_text' | i18next:
         { credit: (pcCredit.price_unit | localized_currency) }"></p>
      <p [innerHTML]="'checkout_summary_pc_credit_general_text' | i18next"></p>
    </div>

    <div class="my-3" *ngIf="saleOrder.deliveryDate">
      <h3 class="h3">{{ 'checkout_delivery_date' | i18next: {date: (saleOrder.deliveryDate | localized_date)} }}</h3>
      <p [innerHTML]="'checkout_delivery_date_text' | i18next:
      {date: (saleOrder.deliveryDate | localized_date)}"></p>
    </div>

    <div class="d-flex flex-column justify-content-end mt-5 mb-3">
      <div class="row justify-content-end">
        <app-pascom-checkbox class="align-self-end"
                             [ngClass]="{ 'col-12 col-lg-10 col-xl-8': trunkEnv.hasTrunkAccess }"
                             [text]="'checkout_one_summary_country_accept_terms' | i18next:
                             {country: countryTranslationCode}"
                             (acceptChanged)="acceptAGB($event)"></app-pascom-checkbox>
      </div>
      <div class="row justify-content-end" *ngIf="isTKGNeeded && trunkEnv.hasTrunkAccess">
        <app-pascom-checkbox
                class="col-12 col-lg-10 col-xl-8 align-self-end"
                inputId="checkbox_tkg"
                [text]="tkgText  | i18next"
                (acceptChanged)="acceptTKG($event)"></app-pascom-checkbox>
      </div>
      <button class="btn btn-outline-primary align-self-end" type="submit" (click)="onBuy()"
              [disabled]="!acceptedAGB || (isTKGNeeded && trunkEnv.hasTrunkAccess && !acceptedTKG)">
        {{ buyBtnTranslation }}
      </button>
    </div>
  </div>
</div>
