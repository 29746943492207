import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AutorunComponent} from "@myshared/autorun.component";
import {Model} from "../../app.model";
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-trial-phonenumber',
  templateUrl: './order-trial-phonenumber.component.html'
})
export class OrderTrialPhonenumberComponent extends AutorunComponent {

  @Input() public stepsBack: number = 1;
  @Input() public historyBack = true;
  @Input() public tenantCountryCode: string;

  @Output() public selected = new EventEmitter<boolean>();
  @Output() public readonly backEmitter = new EventEmitter<void>();

  public nextButtonDisabled = false;
  public hideButton = false;

  constructor(
    private m: Model,
    private router: Router
  ) { super(); }

  ngOnInit(): void {
    this.hideButton = this.router.url.includes('add-phonenumber');
    this.autorun(() => {
      this.tenantCountryCode = this.m.account.currentUser.trunkEnvironment.tenantCountryCode;
    });
  }


  public next(isPhoneNumberSelected) {
    if (isPhoneNumberSelected) {
      this.disableContinueButton();
    }
    this.selected.emit(isPhoneNumberSelected);
  }

  public back() {
    this.backEmitter.emit();
  }

  disableContinueButton() {
    this.nextButtonDisabled = true;
    setTimeout(() => {
      this.nextButtonDisabled = false;
    }, 3000);

  }

  public get phonenumberDescription() {
    const tenantCountryCode = this.tenantCountryCode.toLowerCase();
    return `order_trial_phonenumber_description_${tenantCountryCode}`;
  }
}
