<app-content-header
  (backEvent)="back()"
  [headline]="'cancel_subscription_headline' | i18next:{ applianceId: subscription.appliance_id, applianceName: subscription.name }"></app-content-header>

<div class="col text-align-center mb-5">
  <div class="h3 red">
    <span *ngIf="!isSubscriptionCancellingNow">
      {{ 'cancel_subscription_confirm_date_warning_text' | i18next: {
      nextInvoice: cancelDate,
      interpolation: { escapeValue: false } } }}
    </span>
    <span *ngIf="isSubscriptionCancellingNow">{{ 'cancel_subscription_cancel_now' | i18next }}</span>
  </div>
</div>

<div class="row">
  <div class="col-md-6 offset-md-3">
    <div class="card text-white bg-danger h-100 card-nb">
      <div class="card-body pb-0">
        <h2 class="text-uppercase text-align-center">{{ "warning_headline" | i18next }}</h2>
      </div>
      <div class="card-body pt-0 pb-0">
        <div class="container p-0">
          <div class="row align-content-start" *ngIf="warningPointsList">
            <div class="col p-0">
              <ul class="list-unstyled">
                <li *ngFor="let warn of warningPointsList">
                  <i class="fas fa-plus-circle features-icon mr-2"></i><span>{{ warn }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row mt-3 justify-content-end">
          <button name="cancel-subscription-abort" class="btn btn-link text-light" (click)="back()">{{ "cancel_subscription_abort"  | i18next }}</button>
          <button name="cancel-subscription-confirm" class="btn btn-outline-light" (click)="onCancellingSubscriptionConfirmed()">
            <span *ngIf="!isSubscriptionCancellingNow">
              <span *ngIf="isNotMy && subscription.isOne; else classicOrNotOnePartner">
                {{ 'checkout_summary_create_customer_offer' | i18next: {
                email: customerMail, interpolation: { escapeValue: false } } }}
              </span>
              <ng-template #classicOrNotOnePartner>
                <span>
                  {{ 'cancel_subscription_confirm_date_warning_text' | i18next: {
                  nextInvoice: cancelDate, interpolation: { escapeValue: false } } }}
                </span>
              </ng-template>
            </span>
            <span *ngIf="isSubscriptionCancellingNow">{{ "cancel_subscription_cancel_now" | i18next }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
