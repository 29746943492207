import { Component} from '@angular/core';
import {AutorunComponent} from '@myshared/autorun.component';
import {Model} from '../../app.model';
import {HttpClient} from '@angular/common/http';
import {Router} from "@angular/router";

@Component({
  selector: 'app-select-subscription-product',
  templateUrl: './select-subscription-product.component.html'
})
export class SelectSubscriptionProductComponent extends AutorunComponent {

  public trial;
  public hideBackButton: boolean;
  public isPartnerSelfBuyOne: boolean;

  constructor(
    private m: Model,
    private http: HttpClient,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.isPartnerSelfBuyOne = !!this.router.url.match(/partner/);
    this.http.get<{ basic, pc_one_price }>('/services/products/cloud').subscribe(r => {
      this.trial = {
        highlighted: true,
        recommended: true,
        name: 'pascom_one_product_name',
        description: 'pascom_one_product_description',
        billingPeriod: 'pascom_one_billing_period_text',
        startingPrice: r.pc_one_price,
        features: [
          { name: 'pascom_one_feature_type', highlighted: false },
          { name: 'pascom_one_feature_payment', highlighted: false },
          { name: 'pascom_one_feature_users', highlighted: false }
        ],
        buttonText: 'start_one_trial_button',
        buttonHref: this.buttonLink,
      };

      if (this.m.account.hasTrunkAccess) {
        const tenantCountryCode = this.m.account.currentUser.trunkEnvironment.tenantCountryCode;
        this.trial.features = [
          ...this.trial.features,
          { name: 'pascom_one_feature_trunking', highlighted: false },
          { name: this.getTradingLicenseByType('mobile'), highlighted: false },
          { name: this.getTradingLicenseByType('landline'), highlighted: false }
        ]
      }
    });


    this.autorun(() => {
      this.hideBackButton = this.m.subscription.initialized && this.m.subscription.subscriptions.length === 0;
    });
  }

  public back() {
    if (this.isPartnerSelfBuyOne) {
      this.router.navigate(['/subscriptions', 'my']);
      return;
    }

    this.router.navigate(['/subscriptions']);
  }

  private getTradingLicenseByType(type: 'mobile' | 'landline'): string {
    const tenantCountryCode = this.m.account.currentUser.trunkEnvironment.tenantCountryCode.toLowerCase();
    return `pascom_one_price_${type}_${tenantCountryCode}`;
  }

  private get buttonLink() {
    if (this.m.account.currentUser.companyIsPC20Csp) {
      return '/do/csp/one-trial';
    }

    if (this.m.account.currentUser.companyIsPartner && !this.isPartnerSelfBuyOne) {
      return '/do/partner/one-trial';
    }

    return '/do/one-trial';
  }


}
