<ng-container *ngIf="initialized">
  <app-order-trial-phonenumber
          *ngIf="step === 'add-phonenumber'"
          (selected)="onTrialPhonenumberSelected($event)"
          (backEmitter)="onTrialPhonenumberBack()"
          [historyBack]="false"></app-order-trial-phonenumber>
  <app-phonenumber
          *ngIf="step === 'completed'"
          [trialPhonenumber]="trialPhoneNumber.phonenumber" 
          (selected)="onPhonenumberSelected($event)"></app-phonenumber>
</ng-container>