<div class="row align-items-stretch" *ngIf="subscription">
  <div class="col-12 col-lg-6 mb-3">
    <app-subscription-details-card *ngIf="!subscription.isOneTrial && !subscription.isOne"
                                   [subscription]="subscription"></app-subscription-details-card>
    <app-subscription-one-details-card *ngIf="subscription.isOneTrial || subscription.isOne"
                                       [subscription]="subscription"></app-subscription-one-details-card>
  </div>
  <div class="col-12 col-md-6 mb-3" *ngIf="subscription.isOneTrial">
    <app-subscription-trial-details [subscription]="subscription"></app-subscription-trial-details>
  </div>
  <div class="col-12 col-md-6 mb-3" *ngIf="subscription.isOne">
    <app-subscription-concurrent-user [subscription]="subscription"></app-subscription-concurrent-user>
  </div>
  <div class="col-12 col-md-6 mb-3" *ngIf="subscription.isOne && subscription.sla_product">
    <app-subscription-sla [subscription]="subscription"></app-subscription-sla>
  </div>
  <div class="col-12 col-md-6 mb-3" *ngIf="subscription.isOne && subscription.pcCredit > 0">
    <app-subscription-credits [credit]="subscription.pcCredit"></app-subscription-credits>
  </div>
  <div class="col-12 col-md-6 mb-3" *ngIf="subscription.isOne && subscription.hasActivePhoneNumber">
      <app-fallback-number-card
              [subscription]="subscription"></app-fallback-number-card>
  </div>
  <div class="col-12 col-md-6 mb-3">
    <app-subscription-support-type-card [subscription]="subscription"></app-subscription-support-type-card>
  </div>
</div>

<app-subscription-one-cancel-button *ngIf="subscription" [subscription]="subscription"></app-subscription-one-cancel-button>

