import {Injectable} from '@angular/core';
import {Model} from '../../app.model';
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {OrderService} from "../../order/order.service";

@Injectable()
export class WorkflowCspService {

  private readonly url = 'services/workflow/csp';

  constructor(private m: Model, private http: HttpClient, private saleOrderService: OrderService) {
  }

  public oneApplianceCuChange(applianceId: string, cu: number) {
    return this.http.post<any>(`${this.url}/one/appliance/${applianceId}/cu/change`, {
      cu
    }).pipe(map(r => this.saleOrderService.prepareSaleOrder(r)));
  }

  public oneApplianceSlaChange(applianceId: string, sla: string) {
    return this.http.post<any>(`${this.url}/one/appliance/${applianceId}/sla/change`, {
      sla
    }).pipe(map(r => this.saleOrderService.prepareSaleOrder(r)));
  }

  public oneTrialCreateOrder(data: { instance_name: string, customer_company_id: string, cspdomain: string, acquire_phone_number?: string }) {
    return this.http.post<any>(`${this.url}/one-trial/appliance/create`, data)
      .pipe(map(r => this.saleOrderService.prepareSaleOrder(r)));
  }

  public oneTrialAddPhonenumber(applianceId: string) {
    return this.http.post<any>(`${this.url}/one-trial/appliance/${applianceId}/add-number`, {});
  }
}
