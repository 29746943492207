import {Component, Input, OnInit} from '@angular/core';
import {AutorunComponent} from '@myshared/autorun.component';
import {Model} from '../../app.model';
import {BuyService} from '../buy.service';
import {SaleOrder} from '../buy.model';
import {ActivatedRoute, Router} from '@angular/router';
import {SubscriptionService} from '../../subscription/subscription.service';
import {Subscription} from '../../subscription/subscription.model';

@Component({
  selector: 'app-buy-thankyou',
  templateUrl: './buy-thankyou.component.html'
})
export class BuyThankyouComponent extends AutorunComponent implements OnInit {
  @Input() orderName: string;

  public order: SaleOrder;
  public fromInstance = false;
  public backroute: string[];

  constructor(private m: Model,
              private buyService: BuyService,
              private subscriptionService: SubscriptionService,
              private route: ActivatedRoute,
              private router: Router) {  super(); }

  ngOnInit() {
    if (this.route.snapshot.queryParamMap.has('toinstance')) {
      this.fromInstance = true;
    }
    this.backroute = ['/'];
    this.autorun(() => {
      this.backroute = ['/subscriptions', this.m.buy.currentOrder.aid];
    });

    this.autorun(() => {
      this.order = this.m.buy.currentOrder;
    });
    this.buyService.refresh(this.orderName);
    this.subscriptionService.refresh(true);
  }

  public openBackroute() {
    if (this.fromInstance) {
      const aid = this.m.buy.currentOrder.aid;
      // When we come from instance (commander) -> Upgrade plan and not directly from mypascom
      // We need to read the subscription detail to get the cloud url (only exists in detail)
      this.subscriptionService.getSubscriptionDetails(+aid).subscribe(r => {
        const subscription = Subscription.fromJson(r);
        if (subscription?.cloud_url) {
          window.location.replace(subscription.cloud_url);
          return;
        }
        this.navigateToBackroute();
      });
    } else {
      this.navigateToBackroute();
    }
  }

  private navigateToBackroute() {
    window.history.replaceState(null, null, '/subscriptions');
    this.m.workflow.resetWorkflow();
    this.router.navigate(this.backroute);
  }

  public openDocumentation() {
    window.open('http://doc.pascom.net/');
  }

  public get thankyouContentData() {
    return {
      'headline': 'thank_you_upgrade_headline',
      'content': 'thank_you_upgrade_content_cloud',
      'documentation': null,
      'backrouteButton': 'thank_you_onsite_pascom',
      'showBackrouteButton': true
    };
  }

}
