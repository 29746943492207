import {
  WorkflowEndcustomerOneUpgradeCuComponent
} from "./workflow-endcustomer-one-upgrade-cu/workflow-endcustomer-one-upgrade-cu.component";
import {
  WorkflowEndcustomerOneUpgradeSlaComponent
} from "./workflow-endcustomer-one-upgrade-sla/workflow-endcustomer-one-upgrade-sla.component";
import {
  WorkflowEndcustomerOneDowngradeCuComponent
} from "./workflow-endcustomer-one-downgrade-cu/workflow-endcustomer-one-downgrade-cu.component";
import {
  WorkflowEndcustomerOneDowngradeSlaComponent
} from "./workflow-endcustomer-one-downgrade-sla/workflow-endcustomer-one-downgrade-sla.component";
import {WorkflowEndcustomerService} from "./workflow-endcustomer.service";
import {
  WorkflowEndcustomerOneTrialCreateComponent
} from "./workflow-endcustomer-one-trial-create/workflow-endcustomer-one-trial-create.component";
import { WorkflowEndcustomerAddTrialPhonenumberComponent } from "./workflow-endcustomer-add-trial-phonenumber/workflow-endcustomer-add-trial-phonenumber.component";

export const workflowEndcustomerComponents= [
  WorkflowEndcustomerOneUpgradeCuComponent,
  WorkflowEndcustomerOneUpgradeSlaComponent,
  WorkflowEndcustomerOneDowngradeCuComponent,
  WorkflowEndcustomerOneDowngradeSlaComponent,
  WorkflowEndcustomerOneTrialCreateComponent,
  WorkflowEndcustomerAddTrialPhonenumberComponent,
]

export const workflowEndcustomerServices = [
  WorkflowEndcustomerService,
]
